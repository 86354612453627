import { ConfigVariables } from "adapters/ports/config-variables";
import AccordionComponent from "app/components-finmaq/accordion";
import Card from "components/card/";
import NavBar from "app/components-finmaq/nav-bar";
import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";

interface FAQ {
    title: string;
    body: string;
}

export default function PreguntasFrecuentes() {
    
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [noResults, setNoResults] = useState<boolean>(false);
    const [filteredFaqs, setFilteredFaqs] = useState<FAQ[]>([]);
    const [, setIsFocused] = useState<boolean>(false);

    

    useEffect(() => {
        const faqsData: FAQ[] = [
            {
                title: "1. ¿Qué tipos de créditos ofrece la plataforma?",
                body: "En Finmaq ofrecemos créditos de hasta 20 millones de pesos colombianos para la adquisición de maquinaria a través de nuestros distribuidores aliados, impulsando así el crecimiento de su empresa. Estos créditos están disponibles tanto para personas naturales como jurídicas."
            },
            {
                title: "2. ¿Qué documentos necesito para solicitar un crédito?",
                body:"Para **personas naturales**, se necesita:  \n •  Documento de identificación  \n •  Registro Único Tributario (RUT)  \n •  Extractos bancarios  \n •  Formulario de solicitud  \n •  Información detallada de la máquina que desea adquirir  \n  \nPara **personas jurídicas**, se necesita:  \n •  Documento de identificación del representante legal  \n •  Certificado de Cámara de Comercio Extractos bancarios  \n •  Formulario de solicitud  \n •  Información detallada de la máquina que desea adquirir",
            },
            {
                title: "3. ¿Cómo puedo solicitar un crédito en la plataforma?",
                body:"Para solicitar un crédito, siga estos pasos:  \n  \n • Diríjase a uno de nuestros distribuidores asociados, cotice la máquina que necesita y pida a su asesor que realicen una pre aprobación del crédito con Finmaq.  \n • Siga las instrucciones que recibirá por correo electrónico y espere la respuesta.  \n • Si su crédito es pre aprobado, inicie sesión en nuestra plataforma con las credenciales que le enviamos por correo electrónico.  \n • Suba los documentos solicitados.  \n • Complete el formulario de solicitud con la información requerida y los detalles de la máquina.  \n • Envíe su solicitud para revisión.  \n • Espere la respuesta",
            },
            {
                title: "4. ¿Cuánto tiempo tarda el proceso de revisión del crédito?",
                body: "El proceso de revisión puede tardar entre 3 a 5 días hábiles, dependiendo de la complejidad de su solicitud y de la disponibilidad de toda la documentación requerida."
            },
            {
                title: "5. ¿Cómo puedo saber el estado de mi solicitud?",
                body: "Puede verificar el estado de su solicitud iniciando sesión en su cuenta. Allí encontrará actualizaciones detalladas sobre el progreso de su solicitud. Además, le estaremos notificando por correo electrónico."
            },
            {
                title: "6. ¿Qué hago si tengo problemas para subir los documentos?",
                body:"Si tiene problemas para subir los documentos, asegúrese de que cumplan con los siguientes requisitos:  \n  \n • Formato PDF o JPG  \n • Tamaño máximo de 5 MB por archivo.  \n  \n Si los problemas persisten, puede contactar a nuestro equipo de soporte a través de la sección de “Preguntas frecuentes“ en la parte de “ayuda” en su cuenta.",
            },
            {
                title: "7. ¿Qué sucede después de que mi solicitud es aprobada?",
                body: "Una vez que tu solicitud es aprobada, recibirás una notificación en tu cuenta y por correo electrónico con las instrucciones para completar el proceso y recibir los fondos del crédito."
            },
            {
                title: "8. ¿Puedo editar mi solicitud después de enviarla?",
                body: "No es posible editar una solicitud después de haberla enviado. Si necesita hacer cambios, contacte a nuestro equipo de soporte."
            },
            {
                title: "9. ¿Qué criterios se utilizan para evaluar mi solicitud de crédito?",
                body: "La evaluación de tu solicitud se basa en varios factores, incluyendo tu historial crediticio, la información financiera proporcionada, y la viabilidad del proyecto para el cual se solicita el crédito."
            },
            {
                title: "10. ¿Cómo puedo contactar al soporte si tengo más preguntas?",
                body: "Puedes contactar a nuestro equipo de soporte a través de correo electrónico a **ayuda@finmaq.com.co**"
            },
            
        ]
        
        if (searchTerm === '') {
            setFilteredFaqs(faqsData);
            setNoResults(false);
        } else {
            const filtered = faqsData.filter(faq =>
                removeDiacritics(faq.title.toLowerCase()).includes(removeDiacritics(searchTerm.toLowerCase())) ||
                removeDiacritics(faq.body.toString().toLowerCase()).includes(removeDiacritics(searchTerm.toLowerCase()))
            );
            setFilteredFaqs(filtered);
            setNoResults(filtered.length === 0);
        }
    }, [searchTerm]);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const removeDiacritics = (text: string) => {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const SearchIcon = () => (
        <svg
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400"
            width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
        </svg>
    );

    const ClearIcon = () => (
        <svg
            className="absolute z-50 right-4 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
            width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            onClick={handleClearSearch}
        >
            <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" color="black"/>
        </svg>
    );

    const handleClearSearch = () => {
        setSearchTerm('');
    };


    return (
        <div className='w-full flex flex-col min-h-screen'>
            <div className='hidden lg:flex flex-grow flex flex-col'>
                <div className='mt-20 mb-[21px] flex justify-center flex-grow'>
                    <NavBar />
                    <Card className='mt-4 h-full w-full ml-5 mr-5 rounded-3xl bg-white'>
                        <div className="mt-14 mb-5">
                            <p className="ml-[105px] text-md text-[#2D3748]">Preguntas Frecuentes</p>
                            <p className="mt-[36px] text-4xl text-[#390094] font-bold text-center">Preguntas Frecuentes</p>
                            <div className="flex justify-center items-center mt-[40px]">
                                <img src={ConfigVariables.IMAGEN_FAQ} alt="" className="h-[200px]" />
                            </div>
                            <div className="flex mt-[50px] justify-center items-center">
                                <div className="relative w-[514px] h-12 ">
                                    <input
                                        type="text"
                                        placeholder="Escriba lo que busca aquí"
                                        className="w-full h-full px-4 pt-2 pb-2 focus:border-[#8267BA] text-base placeholder-transparent border rounded-[12px] peer"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                    />
                                    <label
                                        className={`absolute left-4 top-1/2 transform -translate-y-1/2 duration-300 origin-[0]
                                                    peer-placeholder-shown:opacity-0 peer-placeholder-shown:translate-y-2
                                                    peer-focus:-translate-y-8 peer-focus:text-xs focus:text-base 
                                                    ${searchTerm ? '-translate-y-8  bg-white px-1 text-[#A0AEC0] text-xs text-base' : ''}`}
                                    >
                                        Escriba lo que busca aquí
                                    </label>
                                    {searchTerm ? <ClearIcon /> : <SearchIcon />}
                                </div>
                            </div>
                            {noResults && (
                                <div className="text-center mt-28">
                                    <div className="flex justify-center items-center text-center">
                                        <img src={ConfigVariables.ICON_ALERT_FAQS} width={24} height={24} alt='' />
                                    </div>
                                    <p className="mt-2 text-lg font-bold text-#000000">Elemento no encontrado</p>
                                </div>
                            )}
                            <div className="mt-[50px] flex justify-center items-center faq">
                                <div className="w-[820px]">
                                    
                                {filteredFaqs.map((faq, index) => (
                                    <AccordionComponent
                                        key={index}
                                        title={
                                            <Highlighter
                                                searchWords={searchTerm ? [searchTerm] : []}
                                                autoEscape={true}
                                                textToHighlight={faq.title}
                                                highlightStyle={{ color: '#51C1E1', background: '#ffffff' }}
                                            />

                                        }
                                        body={
                                            
                                            <ReactMarkdown
                                                children={faq.body}
                                                className="font-normal leading-6 text-[15px] -mt-6 markdown"
                                                remarkPlugins={[remarkGfm]}
                                            />
                                        }
                                    />
                                ))}
                                    <div className=" mt-[67px]">
                                        <div className="flex justify-center items-center">
                                            <svg width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.4996 9.00224C10.6758 8.50136 11.0236 8.079 11.4814 7.80998C11.9391 7.54095 12.4773 7.4426 13.0006 7.53237C13.524 7.62213 13.9986 7.89421 14.3406 8.30041C14.6825 8.70661 14.8697 9.22072 14.8689 9.75168C14.8689 11.2506 12.6205 12 12.6205 12M12.6495 15H12.6595M12.4996 20C17.194 20 20.9996 16.1944 20.9996 11.5C20.9996 6.80558 17.194 3 12.4996 3C7.8052 3 3.99962 6.80558 3.99962 11.5C3.99962 12.45 4.15547 13.3636 4.443 14.2166C4.55119 14.5376 4.60529 14.6981 4.61505 14.8214C4.62469 14.9432 4.6174 15.0286 4.58728 15.1469C4.55677 15.2668 4.48942 15.3915 4.35472 15.6408L2.71906 18.6684C2.48575 19.1002 2.36909 19.3161 2.3952 19.4828C2.41794 19.6279 2.50337 19.7557 2.6288 19.8322C2.7728 19.9201 3.01692 19.8948 3.50517 19.8444L8.62619 19.315C8.78127 19.299 8.85881 19.291 8.92949 19.2937C8.999 19.2963 9.04807 19.3029 9.11586 19.3185C9.18478 19.3344 9.27145 19.3678 9.44478 19.4345C10.3928 19.7998 11.4228 20 12.4996 20Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </div>
                                        <div className="text-center  justify-center items-center mb-[90px]">
                                            <p className="mt-[14px] text-[#000000] text-md font-semibold">¿Necesita ayuda?</p>
                                            <p className="mt-[14px] text-[#000000] text-sm font-normal">Contacte a nuestro equipo de soporte</p>
                                            <p className="text-[#3696B2] text-sm font-semibold"><a href="mailto:ayuda@finmaq.com.co">ayuda@finmaq.com.co</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div  className='lg:hidden flex-grow flex flex-col w-full h-full '>
                <div  className=' mt-20 flex justify-center flex-grow '>
                    <NavBar></NavBar>
                    <Card className='mt-4 min-h-screen w-full  rounded-t-3xl bg-white'>
                    <div className="mb-5 ml-5 mr-5">
                        <div className="flex items-center mt-7 ml-3">
                            <a href="/mis-creditos">
                                <svg className="text-[#390094]" width={28} height={28} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                            <p className=" ml-2 text-xl text-[#390094] font-bold text-center">Preguntas Frecuentes</p>
                        </div>
                            <div className="flex justify-center items-center mt-[25px]">
                                <img src={ConfigVariables.IMAGEN_FAQ} alt="" width={350} height={148} />
                            </div>
                            <div className="flex mt-[25px] justify-center items-center">
                                <div className="relative w-full h-12 ml-5 mr-5">
                                    <input
                                        type="text"
                                        placeholder="Escriba lo que busca aquí"
                                        className="w-full h-full px-3 pt-2 pb-2 text-base focus:border-[#8267BA] placeholder-transparent border rounded-[12px] peer"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                    />
                                    <label
                                        className={`absolute left-4 top-1/2 transform -translate-y-1/2 duration-300 origin-[0]
                                                    peer-placeholder-shown:opacity-0 peer-placeholder-shown:translate-y-2
                                                    peer-focus:-translate-y-8 peer-focus:text-xs focus:text-base 
                                                    ${searchTerm ? '-translate-y-8  bg-white px-1 text-[#A0AEC0] text-xs text-base' : ''}`}
                                    >
                                        Escriba lo que busca aquí
                                    </label>
                                    {searchTerm ? <ClearIcon /> : <SearchIcon />}
                                </div>
                            </div>
                            {noResults && (
                                <div className="text-center mt-24 mb-20">
                                    <div className="flex justify-center items-center text-center">
                                        <img src={ConfigVariables.ICON_ALERT_FAQS} width={24} height={24} alt='' />
                                    </div>
                                    <p className="mt-2 text-lg font-bold text-#000000">Elemento no encontrado</p>
                                </div>
                            )}
                                <div className="mt-12 flex justify-center items-center">
                                    <div className="w-full">
                                    {filteredFaqs.map((faq, index) => (
                                    <AccordionComponent
                                        key={index}
                                        title={
                                            <Highlighter
                                                searchWords={searchTerm ? [searchTerm] : []} 
                                                autoEscape={true}
                                                textToHighlight={faq.title}
                                                highlightStyle={{ color: '#51C1E1', background: '#ffffff' }}
                                            />

                                        }
                                        body={
                                            <ReactMarkdown
                                                children={faq.body}
                                                className="font-normal leading-6 text-[16px] -mt-6 markdown"
                                                remarkPlugins={[remarkGfm]}
                                            />
                                        }
                                    />
                                ))}
                                    <div className=" mt-8">
                                        <div className="flex justify-center items-center">
                                            <svg width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.4996 9.00224C10.6758 8.50136 11.0236 8.079 11.4814 7.80998C11.9391 7.54095 12.4773 7.4426 13.0006 7.53237C13.524 7.62213 13.9986 7.89421 14.3406 8.30041C14.6825 8.70661 14.8697 9.22072 14.8689 9.75168C14.8689 11.2506 12.6205 12 12.6205 12M12.6495 15H12.6595M12.4996 20C17.194 20 20.9996 16.1944 20.9996 11.5C20.9996 6.80558 17.194 3 12.4996 3C7.8052 3 3.99962 6.80558 3.99962 11.5C3.99962 12.45 4.15547 13.3636 4.443 14.2166C4.55119 14.5376 4.60529 14.6981 4.61505 14.8214C4.62469 14.9432 4.6174 15.0286 4.58728 15.1469C4.55677 15.2668 4.48942 15.3915 4.35472 15.6408L2.71906 18.6684C2.48575 19.1002 2.36909 19.3161 2.3952 19.4828C2.41794 19.6279 2.50337 19.7557 2.6288 19.8322C2.7728 19.9201 3.01692 19.8948 3.50517 19.8444L8.62619 19.315C8.78127 19.299 8.85881 19.291 8.92949 19.2937C8.999 19.2963 9.04807 19.3029 9.11586 19.3185C9.18478 19.3344 9.27145 19.3678 9.44478 19.4345C10.3928 19.7998 11.4228 20 12.4996 20Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </div>
                                        <div className="text-center justify-center items-center mb-14">
                                            <p className="mt-[14px] text-[#000000] text-md font-semibold">¿Necesita ayuda?</p>
                                            <p className="mt-[14px] text-[#000000] text-sm font-normal">Contacte a nuestro equipo de soporte</p>
                                            <p className="text-[#3696B2] text-sm font-semibold"><a href="mailto:ayuda@finmaq.com.co">ayuda@finmaq.com.co</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}
