import React, { useEffect, useState } from 'react';
import { Button, Card } from '@material-tailwind/react';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useNavigate, useLocation } from 'react-router';
import { IoCloseSharp } from "react-icons/io5";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { ConfigVariables } from 'adapters/ports/config-variables';
import { AuthService } from 'http/httpClientService';
import { userPool } from 'app/login/adapters/user-pool-cognito';
import Modal from "app/components-finmaq/modal"; 

export default function Login() {
    const [usuario, setUsuario] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [logoutMensaje, setLogoutMensaje] = useState('');
    const [, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [viewPassword, setViewPassword] = useState(false);
    const [viewNewPassword, setViewNewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
    const [showModalBackground, setShowModalBackground] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPasswordValid, setNewPasswordValid] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(window.location.hash ? true : false);

    const userData = {
        Username: usuario,
        Pool: userPool
    };

    const createAuthenticationDetails = () => {
        return new AuthenticationDetails({
            Username: usuario,
            Password: password
        });
    };

    const updateFirstLoginAttribute = (cognitoUser: CognitoUser): Promise<void> => {
        return new Promise((resolve, reject) => {
            const attributeList = [
                new CognitoUserAttribute({ Name: 'custom:firstLogin', Value: 'false' })
            ];
    
            cognitoUser.updateAttributes(attributeList, (err) => {
                if (err) {
                    reject(err);
                } else {
                    resolve();
                }
            });
        });
    };

    useEffect(() => {
        if (location.state?.message) {
            setLogoutMensaje(location.state.message);
            const timer = setTimeout(() => setLogoutMensaje(''), 3000);
            return () => clearTimeout(timer);
        }
    }, [location.state]);


    const handleLogin = () => {
        setEmailError('');
        setErrorMessage('');
    
        const authenticationDetails = createAuthenticationDetails();
        const cognitoUser = new CognitoUser(userData);
    
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => {
                setIsAuthenticated(true);
                setShowChangePassword(false);
    
                const idToken = session.getIdToken().getJwtToken();
                AuthService.setIdToken(idToken);
    
                cognitoUser.getUserAttributes((err, attributes) => {
                    if (err) {
                        setErrorMessage('Error al obtener atributos del usuario');
                        return;
                    }
    
                    const roleAttr = attributes.find(attr => attr.Name === 'custom:role');
                    const role = roleAttr ? roleAttr.Value : '';
    
                    const firstLoginAttr = attributes.find(attr => attr.Name === 'custom:firstLogin');
                    const firstLogin = firstLoginAttr ? firstLoginAttr.Value === 'true' : false;
    
                    if (role === 'client') {
                        if (firstLogin) {
                            updateFirstLoginAttribute(cognitoUser)
                                .then(() => {
                                    navigate('/beneficios-credito');
                                })
                                .catch(error => {
                                    setErrorMessage('Error al actualizar atributo de primer inicio de sesión');
                                });
                        } else {
                            navigate('/mis-creditos');
                        }
                    } else if (role === 'analist') {
                        navigate('/page-analist');
                    } else {
                        setErrorMessage('Rol desconocido');
                    }
                });
            },
            onFailure: (error) => {
                console.error('Authentication failed', error);
                switch (error.code) {
                    case 'UserNotFoundException':
                    case 'NotAuthorizedException':
                    case 'PasswordResetRequiredException':
                        setErrorMessage('Credenciales inválidas');
                        break;
                    default:
                        setErrorMessage('Error de autenticación');
                        break;
                }
            },
            newPasswordRequired: () => {
                setShowChangePassword(true);
            }
        });
    };

    const handleChangePassword = () => {
        if (newPassword !== confirmPassword) {
            setErrorMessage('Las contraseñas no coinciden');
            return;
        }

        const authenticationDetails = createAuthenticationDetails();
        const cognitoUser = new CognitoUser(userData);
    
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                setIsAuthenticated(true);
                setShowChangePassword(true);
    
                cognitoUser.completeNewPasswordChallenge(newPassword, null, {
                    onSuccess: () => {
                        setSuccessMessage('Cambio de contraseña exitoso');
                        setShowChangePassword(false);
                    },
                    onFailure: (error) => {
                        setErrorMessage('Error al cambiar la contraseña');
                    }
                });
            },
            onSuccess: () => {
            },
            onFailure: (error) => {
                console.error('Change password failed', error);
            }
        });
    };

    const handleCloseMessage = () => {
        setErrorMessage('');
        setSuccessMessage('');
        setShowModalBackground(false);
    };

    const handleKeyPass = async (e: any) => {
        if (e.key === "Enter") {
            await handleLogin();
        }
    };

    const handleKeyChangePass = async (e: any) => {
        if (e.key === "Enter") {
            await handleChangePassword();
        }
    };

    const visibilityPassword = () => {
        setViewPassword(!viewPassword);
    };

    const visibilityNewPassword = () => {
        setViewNewPassword(!viewNewPassword);
    };

    const visibilityConfirmPassword = () => {
        setViewConfirmPassword(!viewConfirmPassword);
    };


    const validateEmail = (email: string): boolean => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value.toLowerCase();
        setUsuario(email);
        
        if (!validateEmail(email)) {
            setEmailError('Correo electrónico inválido');
        } else {
            setEmailError('');
        }
    };

    const validateNewPassword = (password: string): boolean => {
        const isValid = password.length >= 8 && /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9]/.test(password);
        setNewPasswordValid(isValid);
        return isValid;
    };

    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        setNewPassword(password);
        validateNewPassword(password);
    };

    const handleFocus = () => {
        setIsTyping(true);
    };

    const handleBlur = () => {
        setIsTyping(false);
    };

    const handleShowModal = (e:boolean) => {
        setIsModalOpen(e);
        if(window.location.hash) {
            window.history.replaceState("", document.title, window.location.pathname);
       }
    }

    return (
        <div className='h-screen bg-[#ffffff] flex flex-col font-Poppins overflow-auto'>
            <Modal
                className=""
                title="Su sesión ha expirado."
                message="Por favor, inicie sesión nuevamente."
                buttonLabel="Iniciar sesión"
                imageUrl={ConfigVariables.IMAGEN_SESION_EXPIRADA}
                isOpen={isModalOpen}
                handleShowModal={handleShowModal}/>

            {(showChangePassword || showModalBackground || successMessage ) && (
                <div className="fixed top-0 left-0 w-full h-full bg-[#000000] opacity-50 z-40"></div>
            )}
            {errorMessage && (
                <div className="fixed top-0 left-0 w-full h-full bg-[#000000] opacity-50 z-50"></div>
            )}
            <div className='hidden flex-grow xl:grid xl:grid-cols-2'>
                <div className='flex justify-center items-center  lg:mt-[100px] xl:mt-[100px] xl:ml-[90px]'>
                    <div className=' w-[490px]'>
                        <p className='text-[36px] text-[#390094] font-[600] leading-10'>Impulsando sus sueños, fortaleciendo su futuro.</p>
                        <p className='lg:mt-[7px] text-[16px] text-[#4A5568] font-[400]'>Por favor, ingrese sus datos para continuar.</p>
                        <div className="relative w-[400px] lg:mt-[69px] xl:mt-[69px]">
                            <input
                                type="text"
                                value={usuario}
                                onChange={handleEmailChange}
                                placeholder='Correo electrónico'
                                className={`w-full h-12 px-3 text-base placeholder-[#A0AEC0] border rounded-[12px] peer ${emailError ? 'border-[#E53E3E]' : ''} `}
                            />
                            {usuario && (
                                <label className={`absolute left-[-4px] top-1 text-base transition-all duration-300 ${usuario ? 'transform -translate-y-4 scale-75 bg-white px-1' : ''} ${emailError ? 'text-[#E53E3E]' : 'text-[#A0AEC0]'}`}>
                                    Correo electrónico
                                </label>
                            )}
                            {emailError && <p className="flex text-[#E53E3E] ml-3 text-xs">{emailError}</p>}
                        </div>
                        <div className="relative w-[400px] lg:mt-[43px] xl:mt-[43px]">
                            <input
                                type={viewPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyUp={handleKeyPass}
                                placeholder="Contraseña"
                                className={`w-full h-12 px-3 text-base placeholder-[#A0AEC0] border rounded-[12px] peer`}
                            />
                            {password && (
                                <label className={`absolute left-0.5 top-1 text-base transition-all duration-300 ${password ? 'transform -translate-y-4 scale-75 bg-white px-1' : ''} `}>
                                    Contraseña
                                </label>
                            )}
                            <button
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#A0AEC0]"
                                onClick={visibilityPassword}
                            >
                                {viewPassword ? <BsEyeSlash size={18} /> : <BsEye size={18} />}
                            </button>
                        </div>  
                        <div className='w-[400px]'>
                            <a href='/recuperacion-password' className='flex text-[#3696B2] justify-end text-sm mt-7 font-medium'>Olvidé la contraseña</a>
                        </div>

                        {showChangePassword && (
                            <div className="absolute top-0 z-50 left-0 w-full h-full flex justify-center bg items-center z-40">
                                <div className='relative'>
                                    <Card
                                        placeholder={""}
                                        className='bg-white rounded-lg w-[448px] h-[484px] rounded-[28px] shadow-2xl'>
                                        <div className=' ml-[24px] mr-[24px]'>
                                            <p className='text-center text-[#2D3748] text-[16px] mt-[68px]'>Es necesario que cambies<br></br> tu contraseña por seguridad.</p>
                                            <div className="relative w-full mt-[29px]">
                                                <div className="relative w-full mt-9">
                                                    <div className="flex items-center">
                                                        <input
                                                            type={viewNewPassword ? "text" : "password"}
                                                            value={newPassword}
                                                            onChange={handleNewPasswordChange}
                                                            onKeyUp={handleKeyChangePass}
                                                            placeholder="Nueva Contraseña"
                                                            className={`w-[400px] h-12 px-3 text-[15px] placeholder-[#A0AEC0] border rounded-[15px] ${newPassword && !newPasswordValid ? 'border-[#E53E3E]' : ''}`}
                                                        />
                                                        <button
                                                            className={`ml-[-36px] text-[#A0AEC0]`}
                                                            onClick={visibilityNewPassword}
                                                        >
                                                            {viewNewPassword ? <BsEyeSlash size={18} /> : <BsEye size={18} />}
                                                        </button>
                                                    </div>
                                                    {newPassword && (
                                                        <label className={`absolute -left-0 top-1 text-base transition-all duration-300 ${newPassword ? 'transform -translate-y-4 scale-75 bg-white px-1' : ''} ${newPassword && !newPasswordValid ? 'text-[#E53E3E]' : 'text-[#A0AEC0]'}`}>
                                                            Nueva Contraseña
                                                        </label>
                                                    )}
                                                </div>
                                                <div className="relative w-full mt-[29px]">
                                                    <div className="flex items-center">
                                                        <input
                                                            type={viewConfirmPassword ? "text" : "password"}
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                            onKeyUp={handleKeyChangePass}
                                                            placeholder="Confirme su Contraseña"
                                                            className={`w-[400px] h-12 px-3 text-[15px] placeholder-[#A0AEC0] border rounded-[15px] ${confirmPassword && confirmPassword !== newPassword ? 'border-[#E53E3E]' : ''}`}
                                                        />
                                                        <button
                                                            className={`ml-[-36px] text-[#A0AEC0]`}
                                                            onClick={visibilityConfirmPassword}
                                                        >
                                                            {viewConfirmPassword ? <BsEyeSlash size={18} /> : <BsEye size={18} />}
                                                        </button>
                                                    </div>
                                                    {confirmPassword && (
                                                        <label className={`absolute -left-1 top-1 text-base transition-all duration-300 ${confirmPassword ? 'transform -translate-y-4 scale-75 bg-white px-1' : ''} ${confirmPassword && confirmPassword !== newPassword ? 'text-[#E53E3E]' : 'text-[#A0AEC0]'}`}>
                                                            Confirme su Contraseña
                                                        </label>
                                                    )}
                                                </div>

                                                {newPassword !== confirmPassword && <p className="flex text-[#E53E3E] ml-3 text-xs">Las contraseñas no coinciden.</p>}
                                            </div>
                                            <div className={`text-[#4A5568] text-xs mt-9 ${(newPassword !== confirmPassword) ? 'mt-[11px]' : ''}`}>
                                                <p className={`${newPassword ? (newPasswordValid ? 'text-[#38A169]' : 'text-[#E53E3E]') : 'text-[#4A5568]'}`}>
                                                    • Escriba al menos 8 caracteres
                                                </p>
                                                <p className={`${newPassword ? (newPasswordValid ? 'text-[#38A169]' : 'text-[#E53E3E]') : 'text-[#4A5568]'}`}>
                                                    • Combine letras mayúsculas y minúsculas y al menos un símbolo.
                                                </p>
                                            </div>
                                            <div className={`text-xs w-[400px] mt-[28px]`}>
                                                <p className='text-[#4A5568]'>Al hacer clic en "Cambiar Contraseña", acepta nuestros <a href='https://www.finmaq.com.co/politicas-de-tratamiento-datos' className='text-[#3696B2]'>Términos de uso</a> y nuestra <a href='https://www.finmaq.com.co/politicas-de-tratamiento-datos' className='text-[#3696B2]'>Política de privacidad</a>.</p>
                                            </div>
                                            <Button
                                                placeholder={""}
                                                onClick={handleChangePassword}
                                                disabled={!newPassword || !confirmPassword}
                                                className={`w-[400px] mt-7 h-[40px] bg-[#CBD5E0] leading-2 text-[#718096] text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg ${newPassword && confirmPassword ? 'bg-[#390094] text-white' : ''}`}>
                                                <p className='text-[16px] font-semibold normal-case'>
                                                    Cambiar contraseña
                                                </p>
                                            </Button>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        )}
                        {errorMessage && (
                            <div className="absolute top-0 left-0 z-[50] w-full h-full flex justify-center items-center shadow-lg">
                                <Card placeholder={""} className="bg-white z-10 rounded-lg  w-[448px] h-[288px] rounded-[28px] shadow-2xl">
                                    <div className=" flex mt-[15px] ml-[410px]">
                                        <IoCloseSharp className='hover:cursor-pointer w-[22px] h-[22px]' onClick={handleCloseMessage} />
                                    </div>
                                    <div className='flex justify-center mt-[26px]'>
                                        <img src={ConfigVariables.ICON_ALERT_ERROR} width={80} height={80} alt='' />
                                    </div>

                                    <div className='text-center mt-[18px] text-md'>
                                        {errorMessage}
                                    </div>
                                    <Button placeholder={""} onClick={handleCloseMessage} className="w-[400px] h-[40px] mt-[40px] ml-[24px] bg-[#390094] mb-[16px] rounded-[28px] ">
                                        <p className='text-[16px] font-semibold text-white normal-case'>Volver a intentarlo</p></Button>
                                </Card>
                            </div>
                        )}
                        {successMessage && (
                            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-50">
                                <Card placeholder={""} className="bg-white rounded-lg  w-[448px] h-[288px] rounded-[28px] shadow-2xl">
                                    <div className="flex mt-[15px] ml-[410px]">
                                        <IoCloseSharp className='hover:cursor-pointer w-[22px] h-[22px]' onClick={handleCloseMessage} />
                                    </div>
                                    <div className='flex justify-center mt-[30px]'>
                                        <img src={ConfigVariables.ICON_CHECK_SUCCESS} width={90} height={90} alt='' />
                                    </div>

                                    <div className='text-center mt-[28px] text-md'>
                                        {successMessage}
                                    </div>
                                </Card>
                            </div>
                        )}

                        <div>
                            <Button
                                placeholder={""}
                                onClick={handleLogin}
                                disabled={!usuario || !password}
                                className={`w-[400px] mt-7 h-[40px] bg-[#CBD5E0] leading-2 text-[#718096] text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg ${(!usuario || !password) ? '' : 'bg-[#390094] text-white'} ${emailError ? 'mt-[67px]': ''} `}
                            >
                                Iniciar sesión 
                            </Button>
                        </div>
                        
                    
                        <div className={`block ml-32 text-[#A0AEC0] text-sm lg:mt-[100px] xl:mt-[100px] ml-[32px] ${emailError ? 'mt-[12px]': ''}`}>
                            <p>© 2024 Finmaq. Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='w-full h-full rounded-bl-[166px] bg-cover bg-center' style={{ backgroundImage: `url(${ConfigVariables.IMAGEN_LOGIN_DESKTOP})`, backgroundSize: 'cover' }}>
                        <img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="Group" className="absolute mt-20 right-0 mr-16 w-[170px] h-[47px]"/>
                        <div className='absolute bottom-0 right-0'>
                            {logoutMensaje && 
                                <div className='flex justify-end mb-3 mr-3'>
                                    <div className="flex bg-[#C6F6D5] w-[400px] text-md text-end buttom-0 text-[#2D3748] p-2">
                                        <img src={ConfigVariables.ICON_CHECK_SUCCESS} width={20} height={20} alt='' className='mr-3 ml-3' />
                                        {logoutMensaje}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='block xl:hidden min-h-screen'>
                <div className='w-full '>
                    {logoutMensaje && 
                        <div className="flex bg-[#C6F6D5] w-[400px] text-md text-end buttom-0 text-[#2D3748] p-2">
                            <img src={ConfigVariables.ICON_CHECK_SUCCESS} width={20} height={20} alt='' className='mr-3 ml-3' />
                            {logoutMensaje}
                        </div>
                    }
                </div>
                {!isTyping && (
                <div className='relative w-full h-[350px] rounded-bl-[120px] md:h-[650px]' style={{ backgroundImage: `url(${ConfigVariables.IMAGEN_LOGIN_MOBILE})`, backgroundSize: 'cover', backgroundPositionY: 0, backgroundPositionX: 0 }}>
                    <div className="absolute inset-y-0 right-5 flex mt-12 w-[128px] h-[36px]">
                        <img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="" />
                    </div>
                </div>
                )}
                {isTyping && (
                    <div className='relative z-20 top-0 w-full h-[100px] rounded-bl-[120px] md:h-[650px]' style={{ backgroundImage: `url(${ConfigVariables.IMAGEN_LOGIN_MOBILE_BANNER_ESCRITURA})`, backgroundSize: 'cover', backgroundPositionY: 0, backgroundPositionX: 0 }}>
                        <div className="absolute mt-10 ml-32 mr-32 w-[128px] h-[36px]">
                            <img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="" />
                        </div>
                    </div>
                )}
                <div className={`flex justify-center items-center mt-12  mr-5 ml-5 ${isTyping ? 'mt-9' : ''}`}>
                    <div className=' bg-[#ffffff]'>
                        <p className='text-[28px] text-[#390094] font-[600] leading-8 '>Impulsando sus sueños, fortaleciendo su futuro.</p>
                        <p className='lg:mt-[7px] mt-2.5 text-[16px] text-[#4A5568] font-[400]'>Por favor, ingrese sus datos para continuar.</p>
                        <div className={`relative mt-16 lg:mt-[59px] xl:mt-[60px] ${isTyping ? 'mt-8' : ''}`}>
                            <input
                                type="text"
                                value={usuario}
                                onChange={handleEmailChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                placeholder='Correo electrónico'
                                className={`w-full h-12 px-3 text-base placeholder-[#A0AEC0] border rounded-[12px] peer ${emailError ? 'border-[#E53E3E]' : ''} `}
                            />
                            {usuario && (
                                <label className={`absolute left-[-4px] top-1 text-base transition-all duration-300 ${usuario ? 'transform -translate-y-4 scale-75 bg-white px-1' : ''} ${emailError ? 'text-[#E53E3E]' : 'text-[#A0AEC0]'}`}>
                                    Correo electrónico
                                </label>
                            )}
                            {emailError && <p className="flex text-[#E53E3E] ml-3 text-xs">{emailError}</p>}
                        </div>
                        <div className={`relative mt-9 lg:mt-[33px] xl:mt-[35px] ${isTyping ? 'mt-9' : ''}`}>
                            <input
                                type={viewPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyUp={handleKeyPass}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                placeholder="Contraseña"
                                className={`w-full h-12 px-3 text-base placeholder-[#A0AEC0] border rounded-[12px] peer`}
                            />
                            {password && (
                                <label className={`absolute left-0.5 top-1 text-base transition-all duration-300 ${password ? 'transform -translate-y-4 scale-75 bg-white px-1' : ''}`}>
                                    Contraseña
                                </label>
                            )}
                            <button
                                className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400"
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    visibilityPassword();
                                }}
                            >
                                {viewPassword ? <BsEyeSlash className="h-5 w-5" /> : <BsEye className="h-5 w-5" />}
                            </button>
                        </div>
                        <div className=''>
                            <a href='/recuperacion-password' className='flex text-[#3696B2] justify-end text-sm mt-7 font-medium'>Olvidé la contraseña</a>
                        </div>

                        <Button
                            placeholder={""}
                            onMouseDown={handleLogin}
                            disabled={!usuario || !password}
                            className={`w-full mb-16 mt-7 lg:mt-7 xl:mt-7 h-[40px] bg-[#CBD5E0] leading-2 text-[#718096] text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg ${(!usuario || !password) ? '' : 'bg-[#390094] text-white'} ${emailError ? 'mt-[67px]': ''} ${isTyping ? 'mt-9' : ''}`}
                        >
                            Iniciar sesión 
                        </Button>
                    </div>
                </div>
                {showChangePassword && (
                    <div className="absolute top-0 left-0 right-0 w-full h-full bg-white z-40">
                        <p className='text-center text-[#2D3748] text-[16px] mt-14 ml-20 mr-20'>Es necesario que cambies<br></br> tu contraseña por seguridad.</p>
                        <div className='mt-9 mr-5 ml-5'>
                        <div className='relative'>
                            <input
                                type={viewNewPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                onKeyUp={handleKeyChangePass}
                                placeholder="Nueva contraseña"
                                className={`relative w-full h-12 px-3 text-[15px] placeholder-[#A0AEC0] border rounded-[15px] ${newPassword && !newPasswordValid ? 'border-[#E53E3E]' : ''}`}
                            />
                            <button
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#A0AEC0]"
                                onClick={visibilityNewPassword}
                            >
                                {viewNewPassword ? <BsEyeSlash size={18} /> : <BsEye size={18} />}
                            </button>
                            {newPassword && (
                                <label className={`absolute -left-1 top-[5px] text-base transition-all duration-300 ${newPassword ? 'transform -translate-y-4 scale-75 bg-white px-1' : ''} ${newPassword && !newPasswordValid ? 'text-[#E53E3E]' : ''}`}>
                                    Nueva Contraseña
                                </label>
                            )}
                        </div>
                        <div className="relative mt-9">
                            <input
                                type={viewConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                onKeyUp={handleKeyChangePass}
                                placeholder="Confirme su contraseña"
                                className={`w-full h-12 px-3 text-[15px] placeholder-[#A0AEC0] border rounded-[15px] ${confirmPassword && confirmPassword !== newPassword ? 'border-[#E53E3E]' : ''}`}
                            />
                            <button
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#A0AEC0]"
                                onClick={visibilityConfirmPassword}
                            >
                                {viewConfirmPassword ? <BsEyeSlash size={18} /> : <BsEye size={18} />}
                            </button>
                            {confirmPassword && (
                                <label className={`absolute -left-2 top-[5px] text-base transition-all duration-300 ${confirmPassword ? 'transform -translate-y-4 scale-75 bg-white px-1' : ''} ${confirmPassword && confirmPassword !== newPassword ? 'text-[#E53E3E]' : ''}`}>
                                    Confirme su contraseña
                                </label>
                            )}
                        </div>
                                {newPassword !== confirmPassword && <p className="flex text-[#E53E3E] ml-3 text-xs">Las contraseñas no coinciden.</p>}
                            
                                <div className={`text-[#4A5568] text-xs mt-9 ${(newPassword !== confirmPassword) ? 'mt-[11px]' : ''}`}>
                                    <p className={`${newPassword ? (newPasswordValid ? 'text-[#38A169]' : 'text-[#E53E3E]') : 'text-[#4A5568]'}`}>
                                        • Escriba al menos 8 caracteres
                                    </p>
                                    <p className={`${newPassword ? (newPasswordValid ? 'text-[#38A169]' : 'text-[#E53E3E]') : 'text-[#4A5568]'}`}>
                                        • Combine letras mayúsculas y minúsculas y al menos un símbolo.
                                    </p>
                                </div>
                            <div className={`text-xs w-full mt-2.5`}>
                                <p className='text-[#4A5568]'>Al hacer clic en "Cambiar Contraseña", acepta nuestros <a href='https://www.finmaq.com.co/politicas-de-tratamiento-datos' className='text-[#3696B2]'>Términos de uso</a> y nuestra <a href='https://www.finmaq.com.co/politicas-de-tratamiento-datos' className='text-[#3696B2]'>Política de privacidad</a>.</p>
                            </div>
                            <Button
                                placeholder={""}
                                onClick={handleChangePassword}
                                disabled={!newPassword || !confirmPassword}
                                className={`w-full mt-5 h-[40px] bg-[#CBD5E0] leading-2 text-[#718096] text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg ${newPassword && confirmPassword ? 'bg-[#390094] text-white' : ''}`}>
                                <p className='text-[16px] font-semibold normal-case'>
                                    Cambiar contraseña
                                </p>
                            </Button> 

                        </div>

                        
                    </div>
                            
                )}
                {errorMessage && (
                    <div className="absolute flex justify-center items-center left-0 top-0 right-0 z-[50] h-full ml-16 mr-16 shadow-lg">
                        <Card placeholder={""} className="bg-white z-10 rounded-lg  w-auto h-[340px] rounded-[28px] shadow-2xl">
                            <div className="absolute inset-y-0 right-3 flex mt-[10px]">
                                <IoCloseSharp className='hover:cursor-pointer w-[22px] h-[22px]' onClick={handleCloseMessage} />
                            </div>
                            <div className='flex justify-center mt-16'>
                                <img src={ConfigVariables.ICON_ALERT_ERROR} width={80} height={80} alt='' />
                            </div>

                            <div className='text-center mt-8 text-md'>
                                {errorMessage}
                            </div>
                            <Button placeholder={""} onClick={handleCloseMessage} className="w-auto h-auto mt-10 ml-6 mr-6 bg-[#390094] mb-[16px] rounded-[28px] ">
                                <p className='text-[16px] font-semibold text-white normal-case'>Volver a intentarlo</p>
                            </Button>
                        </Card>
                    </div>
                )}
                {successMessage && (
                    <div className="absolute flex justify-center items-center left-0 top-0 right-0 z-[50] h-full ml-16 mr-16 shadow-lg">
                        <Card placeholder={""} className="bg-white z-10 rounded-lg  w-auto h-[340px] rounded-[28px] shadow-2xl">
                            <div className="absolute inset-y-0 right-3 flex mt-[10px]">
                                <IoCloseSharp className='hover:cursor-pointerh-[22px] w-[22px] h-[22px]' onClick={handleCloseMessage} />
                            </div>
                            <div className='flex justify-center mt-16'>
                                <img src={ConfigVariables.ICON_CHECK_SUCCESS} width={90} height={90} alt='' />
                            </div>

                            <div className='text-center mt-8 ml-6 mr-6 text-md'>
                                {successMessage}
                            </div>
                            <Button placeholder={""} onClick={handleCloseMessage} className="w-auto h-auto mt-8 ml-6 mr-6 bg-[#390094] mb-[16px] rounded-[28px] ">
                                <p className='text-[16px] font-semibold text-white normal-case'>Aceptar</p>
                            </Button>
                        </Card>
                    </div>
                )}
            </div>
        </div>
    );
}