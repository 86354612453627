import { FieldsProps, ObjectProps, FormFieldsProps } from "app/formulario-solicitud/utilities/formFields";

interface checkFormValuesProps {
    step:number, 
    highlightErrors:boolean, 
    formFields?:FormFieldsProps[]
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
function isValidEmail(email: string): boolean {
    return emailRegex.test(email);
}

const checkFormValues = ({step, highlightErrors, formFields}: checkFormValuesProps)=>{
    let stepCompleted = true;
    const fieldsCompletedByStep:ObjectProps = {};

    const dataInputsByStep = formFields[step]?.inputs || []
    const filteredData = dataInputsByStep.filter(obj => 'name' in obj);

    filteredData.forEach(({fieldType, section, group="", name, previousLock}:FieldsProps)=>{
        if(previousLock) return;
        let currentField;
        let currentFieldValue;
        const containerField = document.querySelector(`.field-container-${section}_${group}_${name}`) as HTMLInputElement

        if(fieldType === "text" || fieldType === "email" || fieldType === "number" || fieldType === "select" || fieldType === "date" || fieldType === "phone" || fieldType === "checkbox" ) {
            currentField = document.querySelector(`.field-${section}_${group}_${name}`) as HTMLInputElement
            currentFieldValue = currentField?.value;
        }

        if(fieldType === "checkbox") currentFieldValue = currentField?.checked;

        if(fieldType === "radio"){
            currentField = document.querySelector(`.field-${section}_${group}_${name} input:checked`) as HTMLInputElement
            currentFieldValue = currentField?.value;
        }
        
        if(fieldType === "email" && currentFieldValue && !isValidEmail(currentFieldValue.toString())){
            stepCompleted = false;
            if(containerField && highlightErrors){
                containerField.querySelector(".error-message").textContent = "Formato incorrecto";
                containerField.querySelector(".error-message").classList.remove("hidden");
            }
        }

        if(fieldType === "phone"){
            if(currentFieldValue && currentFieldValue.toString().length < 16){
                stepCompleted = false;
                if(containerField && highlightErrors){
                    containerField.querySelector(".error-message").textContent = "Por favor, ingrese un número de teléfono válido";
                    containerField.querySelector(".error-message").classList.remove("hidden");
                }
            }else{
                containerField.querySelector(".error-message").classList.add("hidden");
            }
        }

        if(!currentFieldValue || currentFieldValue === ""){
            stepCompleted = false;
            if(containerField && highlightErrors) containerField.classList.add("error");
        }else{
            const currentSection = fieldsCompletedByStep[section];

            if(currentSection){
                fieldsCompletedByStep[section] = {...currentSection, [name]: currentFieldValue}
            }else{
                fieldsCompletedByStep[section] = {[name]: currentFieldValue}
            }

            if(group){
                const currentGroup = fieldsCompletedByStep[section][group];

                if(currentGroup){
                    fieldsCompletedByStep[section][group] = {...currentGroup, [name]: currentFieldValue}
                }else{
                    fieldsCompletedByStep[section][group] = {[name]: currentFieldValue}
                }
            }

            if(containerField && containerField.classList.contains("error") && highlightErrors) containerField.classList.remove("error");
        }
    })

    return { stepCompleted, fieldsCompletedByStep };
}

export default checkFormValues;