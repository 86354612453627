const departamentos = [
    {label: "Amazonas", value: "Amazonas"},
    {label: "Antioquía", value: "Antioquía"},
    {label: "Arauca", value: "Arauca"},
    {label: "Atlántico", value: "Atlántico"},
    {label: "Bolívar", value: "Bolívar"},
    {label: "Boyacá", value: "Boyacá"},
    {label: "Caldas", value: "Caldas"},
    {label: "Caquetá", value: "Caquetá"},
    {label: "Casanare", value: "Casanare"},
    {label: "Cauca", value: "Cauca"},
    {label: "Cesar", value: "Cesar"},
    {label: "Chocó", value: "Chocó"},
    {label: "Córdoba", value: "Córdoba"},
    {label: "Cundinamarca", value: "Cundinamarca"},
    {label: "Guainía", value: "Guainía"},
    {label: "Guaviare", value: "Guaviare"},
    {label: "Huila", value: "Huila"},
    {label: "La Guajira", value: "La Guajira"},
    {label: "Magdalena", value: "Magdalena"},
    {label: "Meta", value: "Meta"},
    {label: "Nariño", value: "Nariño"},
    {label: "Norte de Santander", value: "Norte de Santander"},
    {label: "Putumayo", value: "Putumayo"},
    {label: "Quindío", value: "Quindío"},
    {label: "Risaralda", value: "Risaralda"},
    {label: "San Andrés y Providencia", value: "San Andrés y Providencia"},
    {label: "Santander", value: "Santander"},
    {label: "Sucre", value: "Sucre"},
    {label: "Tolima", value: "Tolima"},
    {label: "Valle del Cauca", value: "Valle del Cauca"},
    {label: "Vaupés", value: "Vaupés"},
    {label: "Vichada", value: "Vichada"}
]


export default departamentos