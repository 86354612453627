import React from "react";
import { useState } from "react";
import { Link } from "react-scroll";
import { BsArrowLeftCircle } from "react-icons/bs"; 


import { useNavigate } from "react-router-dom";

export default function Header({ bgColor = "", fixed = false, links = false, back = false }) {
  

  const navigate = useNavigate();
  const [menuopen, setMenuOpen] = useState(false);



  return (

    <header className={"header w-screen z-10 bg-[#390094] " + bgColor + " " + fixed}>
      <nav>
        
        <div className="flex justify-around">
        {back && <BsArrowLeftCircle className="absolute self-center left-5 md:left-20" color="white" size={40} onClick={()=>{navigate(-1)}}/>}
          <div className="logo w-36 p-5 md:w-64">
            <Link
              className="link cursor-pointer"
              to="home"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1004.94 278.26"
              >
                <g id="Capa_2" data-name="Capa 2">
                  <g
                    className="link cursor-pointer"
                    fill="#ffffff"
                    id="Layer_1"
                    data-name="Layer 1"
                  >
                    <path d="M217.59,116.1l18.5,14.74a6.37,6.37,0,0,0,6.61.9,6,6,0,0,0,3.61-5.43V17.68A17.43,17.43,0,0,0,235.59,1.62,18.61,18.61,0,0,0,216,4.38L132.82,78.51a17.24,17.24,0,0,0-.07,26.54l71.58,56.71-81.18,76.47L42,161.76l71.57-56.71a17.23,17.23,0,0,0-.06-26.54L30.32,4.38a18.61,18.61,0,0,0-19.6-2.76A17.43,17.43,0,0,0,0,17.68V126.31a6,6,0,0,0,3.61,5.43,6.37,6.37,0,0,0,6.61-.9L28.71,116.1a5.93,5.93,0,0,0,2.16-4.53V51l46.78,40.9L6.81,148a17.23,17.23,0,0,0-.69,25.86L110.8,272.43a18.39,18.39,0,0,0,12.35,5h0a18.42,18.42,0,0,0,12.36-5l104.68-98.62A17.23,17.23,0,0,0,239.5,148L168.65,91.88,215.43,51v60.59a6,6,0,0,0,2.16,4.53" />
                    <path d="M317.52,61.77h91.9q4.54,0,6.47,1.8t1.91,6.34V84.76q0,4.55-1.91,6.34t-6.47,1.8H339.3v31.57h53.2q4.54,0,6.46,1.79t1.92,6.35v14.84q0,4.55-1.8,6.35c-1.19,1.19-3.39,1.79-6.58,1.79H339.3V209c0,3-.6,5.15-1.8,6.34s-3.31,1.8-6.34,1.8H317.52c-3,0-5.19-.6-6.47-1.8s-1.91-3.31-1.91-9.46V66.79a9.34,9.34,0,0,1,1.91-3.22c1.28-1.2,3.43-1.8,6.47-1.8" />
                    <path d="M447.43,61.67h1.67q8.38,0,11.49,3.11T463.7,76v2.16q0,8.37-3.11,11.49T449.1,92.79h-1.67q-8.15,0-11.37-3.11t-3.23-11.49V76q0-8.13,3.11-11.25t11.49-3.11m-7.71,43.58H456q4.06,0,5.74,1.56t1.68,5.62v96.92c0,2.72-.56,4.63-1.68,5.75s-3,1.67-5.74,1.67H439.72c-2.72,0-4.59-.56-5.63-1.67s-1.56-3-1.56-5.75V112.43q0-4.07,1.56-5.62c1-1,2.91-1.56,5.63-1.56" />
                    <path d="M489.09,100.8c13.24-5.42,27.72-8.13,45.52-8.13,16.39,0,31.8,4.11,40,12.32s12.29,17,12.29,32.67v72.05c0,2.71-.55,4.63-1.65,5.74s-3,1.68-5.67,1.68H563.45q-4,0-5.55-1.68c-1-1.11-1.54-3-1.54-5.74V138.38c0-15.64-8.59-20.34-25.76-20.34-7.89,0-12.33,1-19.58,3.11a2.46,2.46,0,0,0-1.65,2.39v86.17c0,2.71-.51,4.63-1.54,5.74s-4.95,1.68-7.63,1.68h-13q-4,0-5.67-1.68c-1.11-1.11-1.66-3-1.66-5.74V114.45q0-5.74,1.78-8.5t7.44-5.15" />
                    <path d="M751,61.33H764.2c2.88,0,4.9.6,6,1.8S772,66.44,772,69.47V208.59c0,3-.57,5.15-1.71,6.34s-3.16,1.8-6,1.8H750.51q-4.32,0-6-1.8c-1.14-1.19-1.71-3.31-1.71-6.34V150.13q0-21.3,5.47-47.63h-1.6c-4.1,13.09-8.25,27.69-16.59,43.8l-22.32,41.41c-2.28,3.83-5.23,5.75-8.88,5.75h-7.28a10.32,10.32,0,0,1-5.36-1.08c-1.13-.72-2.31-2.28-3.52-4.67l-19.6-41.65q-10-17.7-17.54-43.56H644a248.62,248.62,0,0,1,4.78,47.87v58.22c0,3-.57,5.15-1.71,6.34s-3.15,1.8-6,1.8H626.9q-4.33,0-6.15-1.8c-1.22-1.19-1.82-3.31-1.82-6.34V69.47c0-3,.6-5.15,1.82-6.34s3.26-1.8,6.15-1.8h13.19q4.78,0,6.94,1.2c1.45.8,2.77,2.47,4,5l44.19,89.05,45.28-88.81q2.06-4.07,4-5.26t6.49-1.2" />
                    <path d="M791.69,116.83q11.44-23.39,43.87-23.39,45.36,0,45.36,46.33V198q0,5.85-1.59,8.32t-6,4.49q-12.93,5.85-38.15,5.85-27.14,0-37.09-9-10.17-8.77-10.17-27.44,0-20,11-28.11T836,144l20.34,1.13v-6.3q0-11.25-5-16.76t-16.21-5.51q-15.69,0-22.25,10.8-2.34,3.6-3.93,4.16c-1.05.38-2.79.11-5.19-.79L795.29,127q-7-3.37-3.6-10.12m64.64,73.31V166.53c-4.23-.15-8.3-.34-12.18-.56s-6.65-.37-8.27-.45-2.51-.11-2.65-.11q-11.24,0-15.79,3.48T812.89,180q0,7.88,4.34,11.47t15.58,3.6q13.14,0,21.19-1.8a3.46,3.46,0,0,0,2.33-3.15" />
                    <path d="M949.64,93.88c18.35,0,35.91,2.32,46.44,7q5.74,2.48,7.3,4.71t1.56,7.62V271.31c0,2.54-.57,4.33-1.68,5.38s-3,1.57-5.74,1.57H981.23c-2.71,0-4.58-.53-5.62-1.57s-1.56-2.84-1.56-5.38V212.46q-16.51,4.26-28.72,4.26-24.9,0-37.34-11.55t-12.45-33.51V141q0-47.07,54.1-47.07m-26.33,48V171q0,10.55,6.7,16.25T949.4,193q9.81,0,24.65-3.37V123c0-1.19-.88-2.16-2.63-2.91a77.27,77.27,0,0,0-19.87-2.47q-28.24,0-28.24,24.21" />
                  </g>
                </g>
              </svg>
            </Link>
          </div>
          
          {links && (
            <>
              <div
                className="block md:hidden absolute right-5 top-5 space-y-2 cursor-pointer"
                onClick={() => setMenuOpen(!menuopen)}
              >
                {menuopen?
                  <div className="flex flex-col justify-center align-middle">
                    <div className="w-10 h-1 bg-blue -rotate-45 link"></div>
                    <div className="w-10 h-1 bg-blue rotate-45 link"></div>
                  </div>:<>
                  <div className="w-10 h-1 bg-blue link"></div>
                  <div className="w-10 h-1 bg-blue link "></div>
                  <div className="w-10 h-1 bg-blue link"></div>
                </>}
                
                
              </div>
              <div className="font-poppins text-white  sections md:flex space-x-10 self-center hidden ">
                <Link
                  className="link cursor-pointer"
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Inicio
                </Link>
                <Link
                  className="link cursor-pointer"
                  activeClass="active"
                  to="who-we-are"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  ¿Quiénes somos?
                </Link>
                <Link
                  className="link cursor-pointer"
                  activeClass="active"
                  to="financial-solutions"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  Soluciones financieras
                </Link>
                <Link
                  className="link cursor-pointer"
                  activeClass="active"
                  to="partners"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  Alianzas comerciales
                </Link>
                <Link
                  className="link cursor-pointer"
                  activeClass="active"
                  to="contactenos"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  Contacto
                </Link>
              </div>
            </>
          )}
        </div>
        {menuopen && <div className="h-auto w-full p-10 space-y-10 bg-[#390094] flex flex-col justify-around text-white">
        <Link
                  className="link cursor-pointer text-2xl"
                  onClick={()=> setMenuOpen(false)}
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Inicio
                </Link>
                <Link
                  className="link cursor-pointer text-2xl"
                  onClick={()=> setMenuOpen(false)}
                  activeClass="active"
                  to="who-we-are"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  ¿Quienes somos?
                </Link>
                <Link
                  className="link cursor-pointer text-2xl"
                  onClick={()=> setMenuOpen(false)}
                  activeClass="active"
                  to="financial-solutions"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  Soluciones financieras
                </Link>
                <Link
                  className="link cursor-pointer text-2xl"
                  onClick={()=> setMenuOpen(false)}
                  activeClass="active"
                  to="partners"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  Alianzas comerciales
                </Link>
                <Link
                  className="link cursor-pointer text-2xl"
                  onClick={()=> setMenuOpen(false)}
                  activeClass="active"
                  to="contactenos"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  Contacto
                </Link>
          </div>}
      </nav>
    </header>
  );
}
