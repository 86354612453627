type RowObj = {
	name: string;
	email: string;
	price: string;
	date: string;
	status: string;
	actions: string;
};

const tableDataOrders: RowObj[] = [
	{
		name: 'Venus Dashboard Builder PRO',
		email: 'vlad@simmmple.com',
		price: '$59.90',
		date: 'Oct 24, 2022',
		status: 'Completed',
		actions: 'Actions'
	},
	{
		name: 'Venus Design System PRO',
		email: 'fredy@simmmple.com',
		price: '$149.90',
		date: 'Nov 17, 2019',
		status: 'Completed',
		actions: 'Actions'
	},
	{
		name: 'Vision UI Dashboard Chakra PRO',
		email: 'mark@yahoo.com',
		price: '$69.00',
		date: 'Jan 30, 2021',
		status: 'Completed',
		actions: 'Actions'
	},
	{
		name: 'Purity UI Dashboard PRO',
		email: 'markus.a@gmail.com',
		price: '$69.00',
		date: 'Aug 02, 2021',
		status: 'Completed',
		actions: 'Actions'
	},
	{
		name: 'Argon Dashboard Chakra PRO',
		email: 'lorentz@gmail.com',
		price: '$129.90',
		date: 'Apr 19, 2021',
		status: 'Completed',
		actions: 'Actions'
	},
	{
		name: 'Vision UI Dashboard MUI PRO',
		email: 'wilson.tim@msn.com',
		price: '$349.90',
		date: 'Sep 12, 2021',
		status: 'Completed',
		actions: 'Actions'
	},
	{
		name: 'Purity UI Dashboard PRO',
		email: 'markus.a@gmail.com',
		price: '$69.00',
		date: 'Aug 02, 2021',
		status: 'In Progress',
		actions: 'Actions'
	},
	{
		name: 'Argon Dashboard Chakra PRO',
		email: 'lorentz@gmail.com',
		price: '$129.90',
		date: 'Apr 19, 2021',
		status: 'Completed',
		actions: 'Actions'
	},
	{
		name: 'Vision UI Dashboard MUI PRO',
		email: 'wilson.tim@msn.com',
		price: '$349.90',
		date: 'Sep 12, 2021',
		status: 'Completed',
		actions: 'Actions'
	}
];

export default tableDataOrders;
