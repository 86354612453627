import { useState, useRef, useEffect } from "react";
import BtnUploadFile from "./btnUploadFile"; 
import StatusUploadFile from "./statusUploadFile";
import useWindowDimensions from "app/carga-documentos/utilities/useWindowDimensions";
import breakpoint from "app/carga-documentos/utilities/breakpoint";
import ModalEliminarArchivos from "../modal-eliminar-archivos";
import { useCognitoUserAttributes } from "app/login/adapters/atributos-user-pool";
import deleteFilesService from "app/carga-documentos/adapters/ports/http-delete-files-service";

interface CardProps {
  fileLimit: number;
  fileStatus: string;
  fileName?: string;
  className?: string;
  description?: React.ReactNode;
  buttonLabel?: string;
  modal?: React.ReactNode;
  handleInput: (e: {}) => void;
  handleShowModal?: (e: boolean) => void;
}

const CardUploadFile = ({
  fileName = "",
  fileLimit,
  fileStatus,
  className = "",
  description = "",
  buttonLabel = "Elija un archivo",
  modal = null,
  handleInput,
  handleShowModal,
}: CardProps) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [extension, setExtension] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [isModalClicked, setIsModalClicked] = useState(false);
  const [fetchFilename, setFetchFilename] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { person_type, identity_type, identity_number, product_id } = useCognitoUserAttributes();

  const { width } = useWindowDimensions();
  const fileNameToShow = selectedFile ? selectedFile.name : fileName;
  const inputRef = useRef<HTMLInputElement>(null);

  const onChooseFile = () => {
    if (modal && width <= breakpoint && !isModalClicked) {
      handleShowModal(true);
      setIsModalClicked(true);
    } else {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const extension = file?.name.split(".").pop();

      const isCorrectFileSize = file.size <= fileLimit;
      const isCorrectExtension =
        extension === "pdf" || extension === "jpg" || extension === "jpeg";

      if (isCorrectExtension) setExtension(extension);
      if (isCorrectFileSize) setFileSize(file.size);

      setSelectedFile(file);

      if (isCorrectExtension && isCorrectFileSize) handleInput(file);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const clearFileInput = () => {
    if (fetchFilename) {
      setIsModalOpen(true);
    } else {
      inputRef.current.value = "";
      setSelectedFile(null);
      setExtension("");
      setFileSize(0);
      setFetchFilename("");
      handleInput(null);
    }
  };

  const handleOnProp = async () => {

    if (!person_type || !identity_type || !identity_number || !product_id) {
      return;
    }

    try {
      
      const parts = fileName.split("/");
      let document_type = parts[parts.length - 1];
      document_type = document_type.split(".")[0];

      await deleteFilesService.delete(person_type, identity_type, identity_number, document_type, product_id);

      inputRef.current.value = "";
      setSelectedFile(null);
      setExtension("");
      setFileSize(0);
      setFetchFilename("");
      handleInput(null);
      setIsModalOpen(false)
    } catch (error) {
      console.error("Error al eliminar el archivo:", error);
    }
  };
  
  

  useEffect(() => {
    if (fileName.split(".").pop()) setExtension(fileName.split(".").pop());
    if (fileName !== "") {
      setFileSize(1);
      setFetchFilename(fileName);
    }
  }, [fileName]);

  return (
    <div className={`${className}`}>
      <div className="w-auto">
        {modal}
        {isModalOpen && (
          <ModalEliminarArchivos
            onClose={handleModalClose}
            onHandleProp={handleOnProp}
          />
        )}
        <input
          ref={inputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        {description}

        {(!selectedFile && fetchFilename === "") && (
          <div onClick={onChooseFile}>
            <BtnUploadFile
              buttonLabel={buttonLabel}
              fileLimit={fileLimit}
            ></BtnUploadFile>
          </div>
        )}

        {(fetchFilename !== "" || selectedFile) && (
          <StatusUploadFile
            fileName={fileNameToShow}
            extension={extension}
            fileSize={fileSize}
            fileLimit={fileLimit}
            fileStatus={fileStatus}
            clearFileInput={clearFileInput}
          ></StatusUploadFile>
        )}
      </div>
    </div>
  );
};

export default CardUploadFile;

