import FieldProps from "./textFieldProps";

const CheckboxField = ({
  model,
  setModel,
  campoId,
  title,
  required,
  opcionTrue,
  opcionFalse,
  body,
  onChange = () => {},
}: FieldProps) => {
  const getNestedValue = (obj: any, fieldId: string) => {
    const keys = fieldId.split(".");
    return keys.reduce((acc, key) => acc && acc[key], obj);
  };

  const setNestedValue = (obj: any, path: string, value: any) => {
    const keys = path.split(".");
    const lastKey = keys.pop();

    let current = obj;
    for (let key of keys) {
      if (!current[key]) {
        current[key] = {};
      }
      current = current[key];
    }

    if (lastKey) {
      current[lastKey] = value;
    }

    return obj;
  };

  const handleChange = (campoId: string, value: any) => {
    setModel((prevModel: any) => {
      const updatedModel = { ...prevModel };
      setNestedValue(updatedModel, campoId, value);
      return updatedModel;
    });

    onChange(value);
  };

  return (
    <>
      <div className="hidden lg:flex w-auto">
        <div>
          <div className="items-center w-auto">
            <div className="text-sm text-[#171923] font-semibold">{title}</div>
          </div>
          <div className="text-sm font-normal ">
            <div>{body}</div>
          </div>
          <div className="mt-5 text-md flex items-center">
            <label className="flex items-center cursor-pointer">
              <input
                className="sr-only"
                type="radio"
                name={campoId}
                required={required}
                checked={getNestedValue(model, campoId) === true}
                onChange={() => handleChange(campoId, true)}
              />
              <div
                className={`w-[20px] h-[20px] border border-gray-300 rounded-full flex-shrink-0 ${
                  getNestedValue(model, campoId) === true
                    ? "border-[#390094] border-[6px]"
                    : ""
                }`}
              ></div>
              <span className="ml-2">{opcionTrue}</span>
            </label>
            <label className="flex items-center ml-36 cursor-pointer">
              <input
                className="sr-only"
                type="radio"
                name={campoId}
                required={required}
                checked={getNestedValue(model, campoId) === false}
                onChange={() => handleChange(campoId, false)}
              />
              <div
                className={`w-[20px] h-[20px] border border-gray-300 rounded-full flex-shrink-0 ${
                  getNestedValue(model, campoId) === false
                    ? "border-[#390094] border-[6px]"
                    : ""
                }`}
              ></div>
              <span className="ml-2">{opcionFalse}</span>
            </label>
          </div>
        </div>
      </div>
      <div className="lg:hidden w-auto">
        <div className="flex items-center w-auto">
          <div className="text-md text-[#171923] font-semibold">{title}</div>
        </div>
        <div className="text-md font-normal">
            <div>{body}</div>
        </div>
        <div className="mt-5 text-md flex">
          <label className="flex items-center cursor-pointer">
            <input
              className="sr-only"
              type="radio"
              name={campoId}
              required={required}
              checked={getNestedValue(model, campoId) === true}
              onChange={() => handleChange(campoId, true)}
            />
            <div
              className={`w-[20px] h-[20px] border border-gray-300 rounded-full flex-shrink-0 ${
                getNestedValue(model, campoId) === true
                  ? "border-[#390094] border-[6px]"
                  : ""
              }`}
            ></div>
            <span className="ml-2">{opcionTrue}</span>
          </label>
          <label className="flex items-center ml-36 cursor-pointer">
            <input
              className="sr-only"
              type="radio"
              name={campoId}
              required={required}
              checked={getNestedValue(model, campoId) === false}
              onChange={() => handleChange(campoId, false)}
            />
            <div
              className={`w-[20px] h-[20px] border border-gray-300 rounded-full flex-shrink-0 ${
                getNestedValue(model, campoId) === false
                  ? "border-[#390094] border-[6px]"
                  : ""
              }`}
            ></div>
            <span className="ml-2">{opcionFalse}</span>
          </label>
        </div>
      </div>
    </>
  );
};

export default CheckboxField;
