import { useState } from "react";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import HttpClientService from "http/httpClientService";
import { ConfigVariables } from "adapters/ports/config-variables";
import useProductData from "app/carga-documentos/utilities/useProductData";

interface GetDocumentsProps {
  productIndex: number;
}

const useDocumentState2 = ({productIndex}:GetDocumentsProps)=> {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileStatus, setFileStatus] = useState<string>(uploadStatus.DISABLE);
  const [documentUrl, setDocumentUrl] = useState<string>("");

  const {productData, person_type, identity_type, identity_number} = useProductData();

  const selectedProductData = productData[productIndex as keyof typeof productData] || {};

  const product_uuid = (selectedProductData as unknown as { product_uuid: string }).product_uuid;

  const apiBaseUrl = ConfigVariables.URL_BASE_API;
  const endpoint = `https://wpuiuyuhme.execute-api.us-east-1.amazonaws.com/dev/mock/server-error`;

  const handleInput = (file:File) =>{
    if(file){
      setFileStatus(uploadStatus.READY);
      setSelectedFile(file);
    }else{
      setFileStatus(uploadStatus.DISABLE);
    }
  }

  const handleSubmit = (document_type:string, callback?: ()=> void)=>{
    if(!selectedFile && callback){
      setFileStatus(uploadStatus.DONE)
      return callback();
    }
    if(!selectedFile) return false;
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("document_type", document_type);
    formData.append("person_type", person_type);
    formData.append("identity_type", identity_type);
    formData.append("identity_number", identity_number);
    formData.append("product_id", product_uuid);

    setFileStatus(uploadStatus.UPLOADING);

    (async () => {
        try {
            await HttpClientService.get<string>(endpoint, formData);
            setFileStatus(uploadStatus.DONE)
            if(callback) callback()
        } catch (error){
          if (error.response && error.response.status === 500) {
            // Redirige a la página de error 500
            window.location.href = "/error-500";
        } else {
            // Manejo de otros errores si es necesario
            console.error("Error en la solicitud:", error);
        }
        }
    })();
  }

  return { fileStatus, documentUrl, setDocumentUrl, handleInput, handleSubmit };
}


export default useDocumentState2;