import httpClientService from "http/httpClientService";
import { ConfigVariables } from "../../../../adapters/ports/config-variables";

class TextOnBoardCarga {
    private urlArchivoTxt = ConfigVariables.TEXT_ONBOARDING ;

    public async obtenerContenidoArchivoTxt(): Promise<string> {
        try {
            const response = await httpClientService.get<string>(this.urlArchivoTxt);
            return response;
        } catch (error) {
            console.error('Error al obtener el contenido del archivo:', error);
            throw error;
        }
    }
}

const textOnBoardCarga = new TextOnBoardCarga();
export default textOnBoardCarga;





