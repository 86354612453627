import {useEffect, useState} from "react";
import { OptionProps } from "app/formulario-solicitud/utilities/formFields";
 
interface RadioInputsProps {
  options: OptionProps[],
  defaultValue: string,
  name: string,
  handleChange: ()=> void
}
 
const RadioInputs = ({options, defaultValue, name, handleChange}: RadioInputsProps)=>{
  const [selectedValue, setSelectedValue] = useState("");
 
  const handleChangeRadioButton = (e: React.ChangeEvent<HTMLInputElement>)=>{
    handleChange();
    setSelectedValue(e.target.value)
  }
 
  useEffect(()=>{
    const value = defaultValue !== undefined ? defaultValue.toString() : undefined
    setSelectedValue(value)
  },[defaultValue])
 
  return <div className="flex lg:justify-between xl:justify-start">
    {options.map((option, optionIndex)=>{
      return <div className="mr-5 xl:mr-16" key={`${optionIndex}_${name}`}>
        <input onChange={handleChangeRadioButton} className="mr-2" id={option.value.toString()} type="radio" name={`${name}`} value={option.value.toString()} checked={selectedValue === option.value.toString()}/>
        <label htmlFor={`${option.value}`}>{option.label}</label>
      </div>
    })}
  </div>
}
 
export default RadioInputs