import { userPool } from "app/login/adapters/user-pool-cognito";
import { useState, useEffect } from "react";
import { ConfigVariables } from "adapters/ports/config-variables";
import HttpClientService from "http/httpClientService";
import { useNavigate } from "react-router-dom";

const useProductData = ()=>{
    const [person_type, setPersonType] = useState<string | null>(null);
    const [identity_type, setIdentityType] = useState<string | null>(null);
    const [identity_number, setIdentityNumber] = useState<string | null>(null);

    const [productData, setProductData] = useState("");
    const [contactData, setContactData] = useState("");
    const [email, setEmail] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        
        const cognitoUser = userPool.getCurrentUser();
        
        if (cognitoUser && (!person_type || !identity_type || !identity_number)) {
            cognitoUser.getSession((err: any, session: any) => {
                if (err) {
                    console.error("Error getting session:", err);
                    return;
                }

                cognitoUser.getUserAttributes((err: any, attributes: any) => {
                    if (err) {
                        console.error("Error getting user attributes:", err);
                        if(err.toString().search("Access Token has been revoked")) navigate("/#token-revoked")
                        return;
                    }

                    const userID = attributes.find((attr: any) => attr.Name === 'custom:userID').Value.split("/");
                    const email = attributes.find((attr: any) => attr.Name === 'email').Value;
                    const [person_type, identity_type, identity_number] = userID;

                    setPersonType(person_type);
                    setIdentityType(identity_type);
                    setIdentityNumber(identity_number);
                    setEmail(email);
                });
            });
        }
        
        if(productData === "" && person_type && identity_type && identity_number){
            (async () => {
                try {
                    const apiBaseUrl = ConfigVariables.URL_BASE_API;

                    const getUserEndpoint = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}`;
                    const response = await HttpClientService.get<string>(getUserEndpoint);
                    const stringifytext = JSON.stringify(response);
                    const parsetext = JSON.parse(stringifytext);
                    
                    setProductData(parsetext.client.products);
                    setContactData(parsetext.client);

                } catch (error) {
                    console.error('Error al obtener la data del usuario:', error);
                    throw error;
                }
            })(); 
        }
            
    },[productData, person_type, identity_type, identity_number, navigate])

    return { productData, contactData, person_type, identity_type, identity_number, email }
}

export default useProductData;
