import { useState, useEffect } from "react";

interface CheckboxProps {
    defaultValue?: boolean,
    section?: string,
    group?: string,
    name?: string,
    content?: React.ReactNode,
    handleChange?: ()=> void
}

const CheckboxInput = ({defaultValue, section, group, name, content, handleChange}: CheckboxProps)=>{
    const [isChecked, setIsChecked] = useState(defaultValue);

    const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>)=>{
        handleChange();
        setIsChecked(Boolean(e.target.value))
    }

    useEffect(()=>{
        setIsChecked(Boolean(defaultValue))
    },[defaultValue])

    return (
        <div className={`mt-5 w-full field-container-checkbox field-container-${section}_${group}_${name}`} key={name}>
            <input className={`relative multistep-checkbox appearance-none border-2 border-[#A0AEC0] rounded w-[19px] h-[19px] checked:bg-[#390094] checked:border-[#390094] cursor-pointer inline-block align-top field-${section}_${group}_${name}`} type="checkbox" id={name} name={name} onChange={handleChangeCheckbox} checked={isChecked}/>
            {content}
        </div>
    )
}

export default CheckboxInput;