import { Button, Card } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { FaSquareMinus } from "react-icons/fa6";
import { IoCheckbox } from "react-icons/io5";
import NavBar from "app/components-finmaq/nav-bar";
import { useEffect, useState, useMemo } from "react";
import useProductData from "app/carga-documentos/utilities/useProductData";
import useGetDocuments from "app/carga-documentos/utilities/useGetDocuments";
import textsByRole from "app/carga-documentos/utilities/textsByRole";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import FormFields, {FieldsProps} from "app/formulario-solicitud/utilities/formFields"; 
import classNameByEvent from "app/carga-documentos/utilities/classNameByEvent";
import HttpClientService from "http/httpClientService";
import { ConfigVariables } from "adapters/ports/config-variables";
import Modal from "app/components-finmaq/modal"; 

const dotsFormat = (x:number)=> {
    if(!x) return;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

type NestedObject = { [key: string]: any };

function findNestedValue<T>(obj: NestedObject, key: string): T | undefined {
    if (typeof obj !== 'object' || obj === null) {
      return undefined;
    }
    
    if (key in obj) {
      return obj[key] as T;
    }
    
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        const result = findNestedValue<T>(obj[prop], key);
        if (result !== undefined) {
          return result;
        }
      }
    }
    
    return undefined;
}

export default function CargaDocumentos() {
    const [documentCondition, setDocumentCondition] = useState("empty");
    const [documentoFiscalCondition, setDocumentoFiscalCondition] = useState("empty");
    const [extractosCondition, setExtractosCondition] = useState("empty");
    const [infoMaquinaCondition, setInfoMaquinaCondition] = useState("empty");
    const [formCondition, setFormCondition] = useState("empty");
    const [documentStatus, setDocumentStatus] = useState("disable");
    const [isModalRadicadoOpen, setIsModalRadicadoOpen] = useState(false);
    const [isModalRadicacionFinalizadaOpen, setisModalRadicacionFinalizadaOpen] = useState(false);
    const [legalDocuments, setLegalDocuments] = useState(false);
    const [legalOrigins, setLegalOrigins] = useState(false);
    const [legalFinmaqgo, setLegalFinmaqgo] = useState(false);

    const { productData, contactData, person_type, identity_type, identity_number } = useProductData();
    const { productIndex } = useParams();
    const { documents } = useGetDocuments();

    const isAllLegalChecked = legalDocuments && legalOrigins && legalFinmaqgo;

    const selectedProductData = productData[productIndex as keyof typeof productData] || {};
    const formFieldsByType =  useMemo(()=>FormFields[person_type as keyof typeof FormFields] || [],[person_type]);
    const firstStep = useMemo(()=>formFieldsByType[0]?.inputs || [], [formFieldsByType])

    const identification_document_title = textsByRole[person_type as keyof typeof textsByRole]?.identification_document_title;
    const commercial_document_title = textsByRole[person_type as keyof typeof textsByRole]?.commercial_document_title;

    const financial_amount = (selectedProductData as unknown as { financial_product: {
        financial_amount: number
    } }).financial_product?.financial_amount;

    const request_reason = (selectedProductData as unknown as { financial_product: {
        request_reason: number
    } }).financial_product?.request_reason;

    const created_at = (selectedProductData as unknown as { created_at: string }).created_at;

    const product_state = (selectedProductData as unknown as { financial_product: {
        product_state: number
    } }).financial_product?.product_state;

    const completedSteps = [documentCondition, documentoFiscalCondition, extractosCondition, infoMaquinaCondition, formCondition].filter((status) => status === "completed").length;

    const navigate = useNavigate();

    const handleDocumentoIdentificacion = () => {
        navigate(`/documento-identificacion/${productIndex}`)
    }
    const handleRUT = () => {
        navigate(`/documentos-fiscales/${productIndex}`)
    }
    const handleExtractosBancarios = () => {
        navigate(`/extractos-bancarios/${productIndex}`)
    }
    const handleFormularioSolicitud = () => {
        navigate(`/formulario-solicitud/${productIndex}`)
    }
    const handleInformacionMaquina = () => {
        navigate(`/informacion-maquina/${productIndex}`)
    }

    const handleSubmit = ()=>{
        if(completedSteps === 5){
            setIsModalRadicadoOpen(true);
        }
    }

    const handleShowModalRadicado = (e:boolean) => setIsModalRadicadoOpen(e);
    const handleShowModalRadicacionFinalizada = (e:boolean) => {
        setisModalRadicacionFinalizadaOpen(e);
        if(e === false){
            navigate(`/mis-creditos`)
        }
    }

    const handleRadicacion = ()=>{
        const selectedProductData = productData[productIndex as keyof typeof productData] || {};

        const product_uuid = (selectedProductData as unknown as { product_uuid: string }).product_uuid;

        const apiBaseUrl = ConfigVariables.URL_BASE_API;
        const endpoint = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}`;
        const payload = {
            product:{
                product_id: product_uuid,
                    "financial_product":{
                    "product_state":"RADICADO"
                }
            }
        };

        (async () => {
            try {
                await HttpClientService.patch<string>(endpoint,payload);
                setIsModalRadicadoOpen(false);
                setisModalRadicacionFinalizadaOpen(true);
            } catch (error){
                console.log(error)
            }
        })();
    }

    useEffect(()=>{
        const selectedProductData = productData[productIndex as keyof typeof productData] || {};
        const product_uuid = (selectedProductData as unknown as { product_uuid: string }).product_uuid;
        const apiBaseUrl = ConfigVariables.URL_BASE_API;
        const endpoint = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}/approvals/${product_uuid}`;

        if(person_type && product_uuid){
            (async () => {
                try {
                    const response = await HttpClientService.get<string>(endpoint);
                    const stringifytext = JSON.stringify(response);
                    const parsetext = JSON.parse(stringifytext);

                    const data = parsetext?.authorizations?.documents_approvals

                    setLegalDocuments(data?.approves_data_handling_policy_documents);
                    setLegalOrigins(data?.approves_funds_origins);
                    setLegalFinmaqgo(data?.approves_terms_finmaq_go);

                } catch (error){
                    console.log(error)
                }
            })();
        }

        if(documents){
            const documentsByProductIndex = documents ? documents[productIndex as keyof typeof documents] : {};

            if(documentsByProductIndex.rut?.file_path || documentsByProductIndex.camara_comercio?.file_path) setDocumentoFiscalCondition("completed");

            if(documentsByProductIndex.identity_1?.file_path && documentsByProductIndex.identity_2?.file_path) setDocumentCondition("completed");
            if(documentsByProductIndex.identity_1?.file_path && !documentsByProductIndex.identity_2?.file_path) setDocumentCondition("incompleted");
            if(!documentsByProductIndex.identity_1?.file_path && documentsByProductIndex.identity_2?.file_path) setDocumentCondition("incompleted");

            if(documentsByProductIndex.extract_1?.file_path || documentsByProductIndex.extract_2?.file_path || documentsByProductIndex.extract_3?.file_path) setExtractosCondition("completed");

            if(request_reason && documentsByProductIndex?.quotation?.file_path) setInfoMaquinaCondition("completed");
            if(!request_reason && documentsByProductIndex?.quotation?.file_path) setInfoMaquinaCondition("incompleted");
            if(request_reason && !documentsByProductIndex?.quotation?.file_path) setInfoMaquinaCondition("incompleted");

            const valuesFirstStep = firstStep.filter(({name, previousLock}:FieldsProps)=>{
                if(previousLock || !name) return false;
                return true
            }).map(({name}:FieldsProps)=> name)

            if(contactData){
                const valuesLength = valuesFirstStep.filter((name)=>findNestedValue({data: contactData["contact" as keyof typeof contactData]}, name) !== undefined).length;

                if(valuesLength === valuesFirstStep.length && !isAllLegalChecked){
                    setFormCondition("incompleted");
                }
                if(isAllLegalChecked){
                    setFormCondition("completed");
                }
            }
            if(completedSteps === 5) setDocumentStatus("ready");
        }
    },[documents, request_reason, productIndex, isAllLegalChecked, contactData, firstStep, completedSteps, identity_number, identity_type, person_type, productData])

    return (
        <div className="w-full flex flex-col min-h-screen">
            <Modal
                className=""
                title=""
                message={<>¿Seguro que quiere <strong>enviar los documentos</strong> para radicar su solicitud?</>}
                buttonLabel="Cancelar"
                actionButtonLabel="Radicar Solicitud"
                imageUrl={ConfigVariables.IMAGEN_CONFIRMACION_DE_ENVIO}
                isOpen={isModalRadicadoOpen}
                handleShowModal={handleShowModalRadicado}
                handleAction={handleRadicacion}/>

            <Modal
                className=""
                title={<span className="text-[#3182CE]">Su solicitud se ha radicado correctamente</span>}
                message={<span className="mb-7 block">¡Gracias por enviar su solicitud! Estamos revisando su información y pronto le informaremos el resultado a través de su correo electrónico.</span>}
                imageUrl={ConfigVariables.ALERT_ICON}
                isOpen={isModalRadicacionFinalizadaOpen}
                handleShowModal={handleShowModalRadicacionFinalizada}/>

            <form className="lg:flex flex-grow flex flex-col">
                <div className="mt-20 mb-[40px] block lg:flex justify-center flex-grow h-[calc(100vh-120px)] lg:h-auto">
                    <NavBar/>
                    <Card  placeholder={""} className="h-auto lg:h-full w-full lg:ml-5 lg:mr-5 lg:mt-4 rounded-t-[20px] lg:rounded-t-3xl">
                        <div className="lg:flex block justify-between ml-6 mr-6 mt-[40px] lg:mt-[80px] lg:mr-[220px] lg:ml-[210px] lg:mt-[116px] lg:mr-[210px]">
                            <div className="relative">
                                {(documents && Object.keys(documents).length > 1) &&
                                    <Link to={`/mis-creditos`} className="inline-block relative lg:absolute left-[-5px] top-[8px] lg:left-[-40px] lg:top-[4px]">
                                        <svg className="text-[#390094]" width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </Link>
                                }
                                <p className="inline-block text-[#390094] text-2xl lg:text-4xl font-bold lg:font-semibold lg:font-bold leading-10">Solicitud de crédito</p>
                                <p className="mt-[4px] text-[#718096] text-base lg:text-base"><span className="block sm:inline-block">INNSA</span><span className="hidden sm:inline"> - </span>${dotsFormat(financial_amount)} - {created_at}</p>
                                <p className="text-[#51C1E1] font-medium mt-6">Documentos requeridos</p>
                            </div>
                            <p className="text-[#390094] text-2xl lg:text-3xl font-semibold right-0 text-end leading-10 mt-[15px] lg:mt-0">{completedSteps}/5</p>
                        </div>
                        {(product_state && product_state.toString() === "RADICADO") &&
                            <div className="ml-6 mr-6 lg:ml-[210px] lg:mr-[210px] bg-[#C6F6D5] flex items-center p-3 mt-3">
                                <img src={ConfigVariables.ALERT_ICON} className="w-[20px] h-[20px] mr-4" alt="Alert icon"/>
                                <div>
                                    <strong className="text-[#000000]">Su solicitud se ha radicado correctamente</strong>
                                    <p className="text-[#000000]">¡Gracias por enviar su solicitud! Estamos revisando su información y pronto le informaremos el resultado a través de su correo electrónico.</p>
                                </div>
                            </div>
                        }
                        <div className={`mt-[15px] ml-6 mr-6 mb-[130px] lg:ml-[210px] lg:mr-[210px] ${product_state && product_state.toString() === "RADICADO" ? "pointer-events-none opacity-30" : ""}`}>
                            <button className="flex justify-between min-h-[50px] w-full border-b-2 text-[#000000] border-[#EAE5F3] p-3 items-center" onClick={handleDocumentoIdentificacion}>
                                <p className="text-md font-normal text-start pr-[10px]">{identification_document_title}</p> 
                                <div className="mr-3 mt-1">
                                    {documentCondition === "completed" &&
                                        <IoCheckbox color="#38A169" fontSize="1.5em" className="relative bottom-1 left-1"/> 
                                    }
                                    {documentCondition === "incompleted" &&
                                        <FaSquareMinus color="#F6AD55" fontSize="1.5em" className="relative bottom-1 left-1"/>
                                    }
                                    {documentCondition === "empty" &&
                                        <IoIosArrowForward />
                                    }
                                </div>
                            </button>
                            <button className="flex justify-between min-h-[50px] w-full border-b-2 text-[#000000] border-[#EAE5F3] p-3 items-center" onClick={handleRUT}>
                                <p className="text-md font-normal text-start pr-[10px] ">{commercial_document_title}</p> 
                                <div className="mr-3 mt-1">
                                    {documentoFiscalCondition === "completed" &&
                                        <IoCheckbox color="#38A169" fontSize="1.5em" className="relative bottom-1 left-1"/> 
                                    }
                                    {documentoFiscalCondition === "empty" &&
                                        <IoIosArrowForward />
                                    }
                                </div>
                            </button>
                            <button className="flex justify-between min-h-[50px] w-full border-b-2 text-[#000000] border-[#EAE5F3] p-3 items-center" onClick={handleExtractosBancarios}>
                                <p className="text-md font-normal text-start pr-[10px] ">Extractos bancarios</p> 
                                <div className="mr-3 mt-1">
                                    {extractosCondition === "completed" &&
                                        <IoCheckbox color="#38A169" fontSize="1.5em" className="relative bottom-1 left-1"/> 
                                    }
                                    {extractosCondition === "empty" &&
                                        <IoIosArrowForward />
                                    }
                                </div>
                            </button>
                            <button className="flex justify-between min-h-[50px] w-full border-b-2 text-[#000000] border-[#EAE5F3] p-3 items-center" onClick={handleInformacionMaquina}>
                                <p className="text-md font-normal text-start pr-[10px] ">Información de la máquina</p> 
                                <div className="mr-3 mt-1">
                                    {infoMaquinaCondition === "completed" &&
                                        <IoCheckbox color="#38A169" fontSize="1.5em" className="relative bottom-1 left-1"/> 
                                    }
                                    {infoMaquinaCondition === "incompleted" &&
                                        <FaSquareMinus color="#F6AD55" fontSize="1.5em" className="relative bottom-1 left-1"/>
                                    }
                                    {infoMaquinaCondition === "empty" &&
                                        <IoIosArrowForward />
                                    }
                                </div>
                            </button>
                            <button className="flex justify-between min-h-[50px] w-full border-b-2 text-[#000000] border-[#EAE5F3] p-3 items-center" onClick={handleFormularioSolicitud}>
                                <p className="text-md font-normal text-start pr-[10px] ">Formulario de solicitud</p> 
                                <div className="mr-3 mt-1">
                                    {formCondition === "completed" &&
                                        <IoCheckbox color="#38A169" fontSize="1.5em" className="relative bottom-1 left-1"/> 
                                    }
                                    {formCondition === "incompleted" &&
                                        <FaSquareMinus color="#F6AD55" fontSize="1.5em" className="relative bottom-1 left-1"/>
                                    }
                                    {formCondition === "empty" &&
                                        <IoIosArrowForward />
                                    }
                                </div>
                            </button>
                        </div>
                        {(product_state && product_state.toString() !== "RADICADO") &&
                            <Button
                                placeholder={""}
                                className={`w-auto ${classNameByEvent(documentStatus)} h-auto rounded-3xl text-md font-semibold normal-case lg:hidden fixed bottom-5 left-1/2 -translate-x-2/4 w-5/6 max-w-[500px]`}
                                onClick={handleSubmit}>
                                    Radicar Solicitud
                            </Button>
                        }
                    </Card>
                </div>
                <footer className="hidden lg:block z-50 fixed bottom-0 h-[70px] bg-gradient-to-b from-white via-[#E9EEF4] to-[#E9EEF4] w-full shadow-none border-2">
                    <div className='text-end h-full rounded-b-[24px] bg-white ml-[18px] mr-[18px] shadow-md'>
                        <div className='mr-[106px]'>
                            {(product_state && product_state.toString() !== "RADICADO") &&
                                <Button  
                                    placeholder={""}
                                    className={`w-auto ${classNameByEvent(documentStatus)} mt-[8px] h-auto rounded-3xl text-md font-semibold normal-case`}
                                    onClick={handleSubmit}>
                                    Radicar Solicitud
                                </Button>
                            }
                        </div>
                    </div>
                </footer>
            </form>
        </div> 
    )
}

    
