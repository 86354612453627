import Header from "app/components-finmaq/header-terminos-y-condiciones"

export default function DeclaracionOrigenFondos() {
    return (
        <div className="bg-white text-justify">
            <Header></Header>
            <hr className="lg:mt-20 mt-10 lg:ml-20 ml-6 lg:mr-20 mr-6"></hr>
            <div className="w-full min-h-screen">
                <div className=" lg:mt-10 mt-5 lg:ml-32 ml-10 mr-10 lg:mr-32 w-auto">
                    <div className="lg:text-2xl text-lg font-bold">DECLARACIÓN DE ORIGEN DE FONDOS</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">Declaro expresamente que los recursos que poseo o posee la empresa provienen de la actividad económica descrita dentro del formulario y/o formato de solicitud.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg"><b>1.</b> Las actividades realizadas por la persona natural/empresa se efectúan dentro del Marco Legal. Los recursos que posee <b>NO provienen de actividad ilícitas de conformidad con el Código Penal Colombiano.</b></div>
                    <div className="lg:mt-5  mt-6 text-md lg:text-lg"><b>2.</b> La información suministrada en el formulario y/o formato de solicitud es veraz y verificable, y me obligo a actualizarla anualmente.</div>
                    <div className="lg:mt-5  mt-6 text-md lg:text-lg"><b>3.</b> Los recursos que se deriven del desarrollo de este contrato <b>NO se destinarán a la financiación del terrorismo, grupos o actividades terroristas.</b></div>
                    <div className="lg:mt-5  mt-6 text-md lg:text-lg"><b>4.</b> No admitiré que terceros efectúen depósitos o pagos a <b>FINANCIAMOS SU MÁQUINA S.A.S.</b> en mi nombre con fondos provenientes o destinados a realizar actividades ilícitas.</div>
                </div>
            </div>
        </div> 
    )
}