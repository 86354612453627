interface BtnProps {
    fileLimit: number,
    buttonLabel: string
}

const BtnUploadFile = ({fileLimit, buttonLabel}: BtnProps) => {
    return(
        <div className="border-[1px]  border-[#718096] rounded-[16px] border-dashed h-[80px] mt-7">
            <div className="bg-[#E2E8F0] p-2 h-full w-full rounded-[16px] flex justify-center content-center cursor-pointer">
                <div className="flex justify-center content-center flex-col text-center">
                    <div className="flex justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3.5 14C4.05228 14 4.5 14.4477 4.5 15V19C4.5 19.2652 4.60536 19.5196 4.79289 19.7071C4.98043 19.8946 5.23478 20 5.5 20H19.5C19.7652 20 20.0196 19.8946 20.2071 19.7071C20.3946 19.5196 20.5 19.2652 20.5 19V15C20.5 14.4477 20.9477 14 21.5 14C22.0523 14 22.5 14.4477 22.5 15V19C22.5 19.7957 22.1839 20.5587 21.6213 21.1213C21.0587 21.6839 20.2957 22 19.5 22H5.5C4.70435 22 3.94129 21.6839 3.37868 21.1213C2.81607 20.5587 2.5 19.7956 2.5 19V15C2.5 14.4477 2.94772 14 3.5 14Z" fill="#3696B2"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.7929 2.29289C12.1834 1.90237 12.8166 1.90237 13.2071 2.29289L18.2071 7.29289C18.5976 7.68342 18.5976 8.31658 18.2071 8.70711C17.8166 9.09763 17.1834 9.09763 16.7929 8.70711L12.5 4.41421L8.20711 8.70711C7.81658 9.09763 7.18342 9.09763 6.79289 8.70711C6.40237 8.31658 6.40237 7.68342 6.79289 7.29289L11.7929 2.29289Z" fill="#3696B2"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 2C13.0523 2 13.5 2.44772 13.5 3V15C13.5 15.5523 13.0523 16 12.5 16C11.9477 16 11.5 15.5523 11.5 15V3C11.5 2.44772 11.9477 2 12.5 2Z" fill="#3696B2"/>
                        </svg>
                        <p className="font-poppins text-[#3696B2] text-sm">{buttonLabel}</p>
                    </div>
                    <p className="font-poppins text-[#A0AEC0] text-xs">Límite de tamaño por archivo: {Math.round(fileLimit / 1024 / 1024 * 10) / 10}MB</p>
                    <p className="font-poppins text-[#A0AEC0] text-xs">Formatos : JPG, PDF</p>
                </div>
            </div>
        </div>  
    )
}

export default BtnUploadFile;