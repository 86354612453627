import { Button, Card } from "@material-tailwind/react";
import { IoCloseSharp } from "react-icons/io5";
import { createPortal } from "react-dom";
import { ReactNode } from 'react';

interface ModalProps {
    className?: string,
    title?: ReactNode | string,
    boldmessage?: string,
    message?: ReactNode | string,
    buttonLabel?: string,
    actionButtonLabel?: string,
    imageUrl: string,
    isOpen?: boolean,
    handleShowModal?: (e:boolean)=> void,
    handleAction?: ()=> void
}

const Modal = ({className, title, boldmessage, message, buttonLabel = "", actionButtonLabel = "", imageUrl, isOpen, handleShowModal, handleAction}: ModalProps)=>{
    if(!isOpen) return null
    return (
        createPortal(
            <div className={`fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-50 z-[53] ${className}`} >
                <div className="absolute top-0 left-0 z-[50] w-full h-full flex justify-center items-center shadow-lg">
                    <Card className="bg-white z-10 rounded-lg w-[90%] max-w-[448px] rounded-[28px] shadow-2xl">
                        <div className="absolute top-5 right-5">
                            <IoCloseSharp color="#2D3748" className="hover:cursor-pointer w-[22px] h-[22px]" onClick={()=> handleShowModal(false)}/>
                        </div>
                        <div className="flex justify-center mt-[26px]">
                            <img className="mx-auto my-6 max-w-[90%]" src={imageUrl} alt="Imagen modal" />
                        </div>
                        {title &&
                            <p className="text-xl mx-auto mt-1 mb-1 max-w-[80%] w-[300px] font-bold text-[#000000] text-center">{title}</p>
                        }
                        {boldmessage &&
                            <div className="text-center m-auto mt-1 mb-4 text-md text-[#2D3748] max-w-[80%] font-semibold">
                                {boldmessage}
                            </div>
                        }
                        <div className="text-center m-auto mt-1 mb-1 text-md text-[#2D3748] max-w-[90%]">
                            {message}
                        </div>
                        <div className="flex px-4 mb-4">
                            { actionButtonLabel !== "" &&
                                <>
                                    <Button className="w-[45%] max-w-[400px] h-[40px] m-auto mt-[40px] mb-[16px] bg-[#EDF2F7] rounded-[28px]" onClick={()=> handleShowModal(false)}>
                                        <p className="text-[16px] font-semibold text-[#1A202C] normal-case">{buttonLabel}</p></Button>
                        
                                    <Button className="w-[45%] max-w-[400px] h-[40px] m-auto mt-[40px] mb-[16px] bg-[#390094] rounded-[28px]" onClick={handleAction}>
                                        <p className="text-[16px] font-semibold text-white normal-case">{actionButtonLabel}</p></Button>
                                </>
                            }

                            { (actionButtonLabel === "" && buttonLabel !== "") &&
                                <Button className="w-[90%] max-w-[400px] h-[40px] m-auto mt-[40px] mb-[16px] bg-[#390094] rounded-[28px]" onClick={()=> handleShowModal(false)}>
                                <p className="text-[16px] font-semibold text-white normal-case">{buttonLabel}</p></Button>
                            }
                        </div>
                    </Card>
                </div>
            </div>, document.body)
    )
}

export default Modal;