import { useNavigate } from "react-router-dom";
import LayoutDocumentos from "./layouts/layout-documentos"
import { useState, useEffect, useCallback } from "react";
import { IoArrowForward } from "react-icons/io5";
import classNameByEvent from "app/carga-documentos/utilities/classNameByEvent";
import Modal from "app/components-finmaq/modal"; 
import { ConfigVariables } from "adapters/ports/config-variables";
import CardUploadFile from "app/components-finmaq/cardUploadFile";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import useWindowDimensions from "app/carga-documentos/utilities/useWindowDimensions";
import useDocumentState from "app/carga-documentos/utilities/useDocumentState";
import breakpoint from "app/carga-documentos/utilities/breakpoint";
import useGetDocuments from "app/carga-documentos/utilities/useGetDocuments";
import useProductData from "app/carga-documentos/utilities/useProductData";
import textsByRole from "app/carga-documentos/utilities/textsByRole";
import { useParams } from 'react-router-dom';
import ModalGuardadoParcial from "app/components-finmaq/modal-guardado-parcial";

export default function CargaDocumentoIdentificacion() {
    const { person_type } = useProductData();
    const { productIndex } = useParams();
    const { documents } = useGetDocuments();

    const { fileStatus:fileStatusOne, 
            documentUrl:documentOneUrl, 
            setDocumentUrl:setDocumentOneUrl, 
            handleInput: handleInputOne, 
            handleSubmit: handleSubmitOne } = useDocumentState({productIndex: Number(productIndex)});
    const [isModalOpenOne, setIsModalOpenOne] = useState(false);
    const [classStepOne, setClassStepOne] = useState("");

    const { fileStatus:fileStatusTwo, 
        documentUrl:documentTwoUrl, 
        setDocumentUrl:setDocumentTwoUrl, 
        handleInput: handleInputTwo, 
        handleSubmit: handleSubmitTwo } = useDocumentState({productIndex: Number(productIndex)});
    const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);
    const [classStepTwo, setClassStepTwo] = useState(uploadStatus.HIDDEN);

    const [arrowStatus, setArrowStatus] = useState(uploadStatus.DISABLE);
    const [buttonStatus, setButtonStatus] = useState(uploadStatus.HIDDEN);
    const [isModalOpenIntro, setIsModalOpenIntro] = useState(true);

    const { width } = useWindowDimensions();

    const handleShowModalIntro = (e:boolean) => setIsModalOpenIntro(e);
    const handleShowModalOne = (e:boolean) => setIsModalOpenOne(e);
    const handleShowModalTwo = (e:boolean) => setIsModalOpenTwo(e);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFileSaved, setIsFileSaved] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const navigate = useNavigate();
    
    const isAnyFieldFilled = useCallback(() => {
        return (
            ((fileStatusOne === uploadStatus.READY || fileStatusTwo === uploadStatus.READY) && !isFileSaved) 
            || (hasChanges && !isFileSaved)
        );
    }, [fileStatusOne, fileStatusTwo, isFileSaved, hasChanges]);

    const handleOpenModal = useCallback(() => {
        if (isAnyFieldFilled()) {
            setIsModalOpen(true);
        } else {
            navigate(`/carga-documentos/${productIndex}`);
        }
    },[isAnyFieldFilled, navigate, productIndex]);


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSendDocuments = () => {
        if (fileStatusOne === uploadStatus.READY && fileStatusTwo !== uploadStatus.READY) {
            handleSubmitOne("IDENTIDAD_1");
            navigate(`/carga-documentos/${productIndex}`);
        }
        if (fileStatusTwo === uploadStatus.READY && fileStatusOne !== uploadStatus.READY) {
            handleSubmitTwo("IDENTIDAD_2");
            navigate(`/carga-documentos/${productIndex}`);
        }
        if (fileStatusOne === uploadStatus.READY && fileStatusTwo === uploadStatus.READY) {
            handleSubmitOne("IDENTIDAD_1");
            handleSubmitTwo("IDENTIDAD_2");
            navigate(`/carga-documentos/${productIndex}`);
        }
    };

    const identification_document_title = textsByRole[person_type as keyof typeof textsByRole]?.identification_document_title;
    const identification_document_description = textsByRole[person_type as keyof typeof textsByRole]?.identification_document_description;

    const handleButtonClick = ()=>{
        if(width <= breakpoint){
            if(fileStatusTwo === uploadStatus.READY) handleSubmitTwo("IDENTIDAD_2");
            setIsFileSaved(true);
        }else{
            if(fileStatusOne === uploadStatus.READY && fileStatusTwo === uploadStatus.READY){
                handleSubmitOne("IDENTIDAD_1");
                handleSubmitTwo("IDENTIDAD_2");
                setIsFileSaved(true);
            }
        }
    }

    const handleArrowClick = ()=>{
        const animationDelay = documentOneUrl ? 1 : 2000;
        if(fileStatusOne === uploadStatus.READY || documentOneUrl) handleSubmitOne("IDENTIDAD_1", ()=>{
            setTimeout(()=>{
                setClassStepOne(uploadStatus.HIDDEN);
                setClassStepTwo("");
                setArrowStatus(uploadStatus.HIDDEN);

                documentTwoUrl ? setButtonStatus(uploadStatus.READY) : setButtonStatus(uploadStatus.DISABLE);
            }, animationDelay)
        });
    }

    useEffect(()=>{
        const documentsByProductIndex = documents ? documents[productIndex as keyof typeof documents] : {};

        if(fileStatusOne === uploadStatus.READY) setArrowStatus(uploadStatus.READY);

        if(fileStatusOne === uploadStatus.DONE && fileStatusTwo === uploadStatus.READY){
            setArrowStatus(uploadStatus.HIDDEN);
            setButtonStatus(uploadStatus.DISABLE);
        }

        if((fileStatusOne === uploadStatus.READY || fileStatusOne === uploadStatus.DONE) && 
        fileStatusTwo === uploadStatus.READY){
            setButtonStatus(uploadStatus.READY);
        }

        if(documentsByProductIndex && documentsByProductIndex.identity_1 && documentsByProductIndex.identity_1.file_path && !documentOneUrl){
            setDocumentOneUrl(documentsByProductIndex.identity_1.file_path);
            setArrowStatus(uploadStatus.READY);
            setIsFileSaved(true);
        } 

        if(documentsByProductIndex && documentsByProductIndex.identity_2 && documentsByProductIndex.identity_2.file_path && !documentTwoUrl){
            setDocumentTwoUrl(documentsByProductIndex.identity_2.file_path); 
            setHasChanges(false);
            setIsFileSaved(true)
        }

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isAnyFieldFilled()) {
                event.preventDefault();
                event.returnValue = ''; 
                handleOpenModal();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

    },[documents, productIndex, fileStatusOne, fileStatusTwo, documentOneUrl, documentTwoUrl, setDocumentOneUrl, setDocumentTwoUrl, handleOpenModal, isAnyFieldFilled ])

    return (
        <LayoutDocumentos
            handleButtonClick={handleButtonClick} 
            buttonLabel="Guardar"
            buttonClassName={`${classNameByEvent(buttonStatus)} lg:inline-block`}
            hasFooter={true}>
            <Modal
                className=""
                title=""
                message="Al subir archivos asegúrese que la imagen esté completa y no tenga tachones, ni partes cortadas ni borrosas."
                buttonLabel="OK"
                imageUrl={ConfigVariables.IMAGEN_RECOMIENDACION_IMAGEN_COMPLETA}
                isOpen={isModalOpenIntro}
                handleShowModal={handleShowModalIntro}/>
            <div className="hidden lg:flex text-[#718096] ml-[105px] text-md font-normal"><span>Solicitud de crédito </span><span className="ml-1 text-[#000000]"> / {identification_document_title}</span></div>

            <div className="lg:mt-7 lg:ml-14 flex items-center">
                {isModalOpen && (
                    <ModalGuardadoParcial onClose={handleCloseModal} onHandleProp={handleSendDocuments} /> 
                )}
                <button type="button" onClick={handleOpenModal}>
                    <svg className="text-[#390094]" width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <p className="text-start text-[#390094] font-bold text-xl ml-2 lg:font-semibold lg:text-2xl lg:ml-[15px]">{identification_document_title}</p>
            </div>
            <div  className="text-[#171923] text-md font-normal lg:ml-[105px] lg:mt-2 mt-7 leading-6 lg:leading-normal w-full lg:w-auto">
                <p>{identification_document_description}</p>
            </div>
            <div className="block lg:flex flex-col lg:flex-row lg:pl-[105px] lg:pr-[105px] lg:gap-11 mt-[60px] lg:mt-[75px]">
                <CardUploadFile
                    key={`documentOne_${documentOneUrl}`}
                    fileLimit={4928307}
                    fileStatus={fileStatusOne}
                    fileName={documentOneUrl}
                    className={`${classStepOne} lg:block lg:w-[50%]`}
                    description={<div>
                        <p className="text-xl font-semibold text-[#000000]">Frente de la identificación</p>
                        <p className="mt-2 font-normal text-md leading-5">Seleccione el archivo de la parte <strong>frontal</strong> del documento de identificación.</p>
                    </div>}
                    modal={
                        <Modal
                            className="lg:hidden"
                            title="Use el lado de su documento donde está la foto"
                            message="Verifique que la imagen que suba esté enfocada y se lean los datos correctamente."
                            buttonLabel="OK"
                            imageUrl={ConfigVariables.IMAGEN_CEDULA_ADELANTE}
                            isOpen={isModalOpenOne} 
                            handleShowModal={handleShowModalOne}/>
                        }
                    handleInput={handleInputOne}
                    handleShowModal={handleShowModalOne}/>
                <CardUploadFile
                    key={`documentTwo_${documentTwoUrl}`}
                    fileLimit={4928307}
                    fileStatus={fileStatusTwo}
                    fileName={documentTwoUrl}
                    className={`${classStepTwo} lg:block lg:w-[50%]`}
                    description={<div>
                        <p className="text-xl font-semibold text-[#000000]">Reverso de la identificación</p>
                        <p className="mt-2 font-normal text-md leading-5">Seleccione el archivo de la parte de <strong>atrás</strong> del documento de identificación.</p>
                    </div>}
                    modal={
                        <Modal
                            className="lg:hidden"
                            title="Ahora dele la vuelta a su documento"
                            message="Verifique que la imagen que suba esté enfocada y se lean los datos correctamente."
                            buttonLabel="OK"
                            imageUrl={ConfigVariables.IMAGEN_CEDULA_ATRAS}
                            isOpen={isModalOpenTwo} 
                            handleShowModal={handleShowModalTwo}/>
                        }
                    handleInput={handleInputTwo}
                    handleShowModal={handleShowModalTwo}/>
            </div>
            <div className="lg:hidden flex justify-end fixed bottom-5 right-5 z-[51]">
                
                <button
                    className={`rounded-[33px] h-[48px] w-[48px] ${classNameByEvent(arrowStatus)}`}
                    type={'submit'}
                    onClick={handleArrowClick}>   
                    <div className="mr-[16px] ml-[16px]">
                        <IoArrowForward size={18} />
                    </div>
                </button>
            </div>
        </LayoutDocumentos>               
    )
}