import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import textOnBoardCarga from "app/on-board/adapters/ports/text-onboarding";
import { ConfigVariables } from "adapters/ports/config-variables";

export default function ComenzarCargaDocumentos() {
    const [textContent, setTextContent] = useState<string>('');

    useEffect(() => {
        const fetchFileContent = async () => {
            try {
                const content = await textOnBoardCarga.obtenerContenidoArchivoTxt();
                setTextContent(content);
            } catch (error) {
                console.error('Error fetching file content:', error);
            }
        };

        fetchFileContent();
    }, []);


    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate('/mis-creditos');
    };

    return (
        <div className='h-screen bg-[#ffffff] flex flex-col font-Poppins overflow-auto'>
            <div className="hidden lg:flex flex-grow">
                <div className="w-1/2 flex justify-center items-center mt-[140px]">
                    <div className='ml-52 mr-40 w-[400px] mt-20'>
                        <div className="font-[400px] font-normal text-[24px] leading-8 text-start">
                            <ReactMarkdown
                                children={textContent}
                                remarkPlugins={[remarkGfm]}
                            />
                        </div>
                        <div className="mt-16">
                            <Button
                                placeholder={""}
                                onClick={handleOnClick}
                                className="w-[400px] h-[40px] bg-[#390094] leading-2 text-white text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg"
                            >
                                Continuar <FiArrowRight className="ml-3" />
                            </Button>
                        </div>
                        <div className=" relative mt-24 text-[#A0AEC0] text-sm  mb-6 text-start">
                            <p>© 2024 Finmaq. Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
                <div className="w-1/2 relative">
                    <div className='w-full h-full rounded-bl-[166px] bg-cover bg-center' style={{ backgroundImage: `url(${ConfigVariables.IMAGEN_PRINCIPAL_ONBOARDING_DESKTOP})`, backgroundSize: 'cover' }}>
                        <img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="Group" className="absolute mt-20 right-0 mr-16 w-[170px] h-[47px]"/>
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <div className="  h-[345px]" style={{backgroundImage: `url(${ConfigVariables.IMAGEN_PRINCIPAL_ONBOARDING_MOBILE})`, backgroundSize: 'cover'}}>
                    <div className='flex justify-center items-center rounded-bl-[166px] w-full'>
                        <img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="Group" className="mt-14 w-[128px] h-[36px]"/>
                    </div>
                </div>
                <div className='mt-[130px] ml-7  mr-7'>
                    <div className="font-[400px] font-normal text-xl leading-7 text-start">
                        <div>
                            <ReactMarkdown
                                    children={textContent}
                                    remarkPlugins={[remarkGfm]}
                            />
                        </div>
                    </div>
                        <div className="mt-[12vh] mb-5">
                            <Button
                                placeholder={""}
                                onClick={handleOnClick}
                                className="w-full h-[40px] bg-[#390094] leading-2 text-white text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg"
                            >
                                Continuar <FiArrowRight className="ml-3" />
                            </Button>
                        </div>
                    </div>    
            </div>
        </div>
    )
}




