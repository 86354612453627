
export const ConfigVariables = {
    COGNITO_USER_POOL_ID : process.env.REACT_APP_COGNITO_USER_POOL_ID,
    COGNITO_CLIENT : process.env.REACT_APP_COGNITO_CLIENT,
    TEXT_ONBOARDING : process.env.REACT_APP_TEXT_ONBOARDING,
    IMAGEN_COMPUTADOR_ONBOARDING : process.env.REACT_APP_IMAGEN_COMPUTADOR_ONBOARDING,
    IMAGEN_CELULAR_ONBOARDING : process.env.REACT_APP_IMAGEN_CELULAR_ONBOARDING,
    IMAGEN_CAFE_ONBOARDING : process.env.REACT_APP_IMAGEN_CAFE_ONBOARDING,
    IMAGEN_MONEDAS_ONBOARDING : process.env.REACT_APP_IMAGEN_MONEDAS_ONBOARDING,
    IMAGEN_WALLET_ONBOARDING : process.env.REACT_APP_IMAGEN_WALLET_ONBOARDING,
    IMAGEN_PRINCIPAL_ONBOARDING_DESKTOP : process.env.REACT_APP_IMAGEN_PRINCIPAL_ONBOARDING_DESKTOP,
    IMAGEN_PRINCIPAL_ONBOARDING_MOBILE : process.env.REACT_APP_IMAGEN_PRINCIPAL_ONBOARDING_MOBILE,
    IMAGEN_LOGO_FINMAQ_DESKTOP : process.env.REACT_APP_IMAGEN_LOGO_FINMAQ_DESKTOP,
    IMAGEN_LOGO_FINMAQ_MOBILE : process.env.REACT_APP_IMAGEN_LOGO_FINMAQ_MOBILE,
    IMAGEN_AVATAR_NAVBAR : process.env.REACT_APP_IMAGEN_AVATAR_NAVBAR,
    IMAGEN_AVATAR_LOGOUT : process.env.REACT_APP_IMAGEN_AVATAR_LOGOUT,
    IMAGEN_LOGIN_DESKTOP : process.env.REACT_APP_IMAGEN_LOGIN_DESKTOP,
    IMAGEN_LOGIN_MOBILE : process.env.REACT_APP_IMAGEN_LOGIN_MOBILE,
    IMAGEN_LOGIN_MOBILE_BANNER_ESCRITURA : process.env.REACT_APP_IMAGEN_LOGIN_MOBILE_BANNER_ESCRITURA,
    ICON_CHECK_SUCCESS : process.env.REACT_APP_ICON_CHECK_SUCCESS,
    ICON_ALERT_ERROR : process.env.REACT_APP_ICON_ALERT_ERROR,
    ICON_ALERT_FAQS : process.env.REACT_APP_ICON_ALERT_FAQS,
    IMAGEN_FAQ: process.env.REACT_APP_IMAGEN_FAQ,
    COMO_SOLICITAR_CREDITO: process.env.REACT_APP_PREGUNTA_COMO_SOLICITAR_CREDITO,
    CONTACTO_SOPORTE: process.env.REACT_APP_PREGUNTA_CONTACTO_SOPORTE,
    CRITERIOS_EVALUACION: process.env.REACT_APP_PREGUNTA_CRITERIOS_EVALUACION,
    CUANTO_TARDA_EL_PROCESO: process.env.REACT_APP_PREGUNTA_CUANTO_TARDA_EL_PROCESO,
    DESPUES_DE_APROBADO: process.env.REACT_APP_PREGUNTA_DESPUES_DE_APROBADO,
    DOCUMENTOS_NECESARIOS: process.env.REACT_APP_PREGUNTA_DOCUMENTOS_NECESARIOS,
    EDITAR_SOLICITUD: process.env.REACT_APP_PREGUNTA_EDITAR_SOLICITUD,
    ESTADO_SOLICITUD: process.env.REACT_APP_PREGUNTA_ESTADO_SOLICITUD,
    PROBLEMAS_SUBIR_DOCUMENTOS: process.env.REACT_APP_PREGUNTA_PROBLEMAS_SUBIR_DOCUMENTOS,
    TIPOS_DE_CREDITOS: process.env.REACT_APP_PREGUNTA_TIPOS_DE_CREDITOS,
    ICON_PDF_FILE : process.env.REACT_APP_ICON_PDF_FILE,
    ICON_JPG_FILE : process.env.REACT_APP_ICON_JPG_FILE,
    ICON_ERROR_FILE : process.env.REACT_APP_ICON_ERROR_FILE,
    IMAGEN_RECOMIENDACION_IMAGEN_COMPLETA : process.env.REACT_APP_IMAGEN_RECOMIENDACION_IMAGEN_COMPLETA,
    IMAGEN_CEDULA_ADELANTE : process.env.REACT_APP_IMAGEN_CEDULA_ADELANTE,
    IMAGEN_CEDULA_ATRAS : process.env.REACT_APP_IMAGEN_CEDULA_ATRAS,
    URL_BASE_API : process.env.REACT_APP_URL_BASE_API,
    IMAGEN_CAMBIAR_PASSWORD : process.env.REACT_APP_IMAGEN_CAMBIAR_PASSWORD,
    IMAGEN_SESION_EXPIRADA : process.env.REACT_APP_IMAGEN_SESION_EXPIRADA,
    ALERTA_CREDITOS_MAYORES : process.env.REACT_APP_ALERTA_CREDITOS_MAYORES,
    IMAGEN_MODAL_ELIMINAR_ARCHIVOS : process.env.REACT_APP_IMAGEN_MODAL_ELIMINAR_ARCHIVOS,
    IMAGEN_MODAL_GUARDADO_PARCIAL : process.env.REACT_APP_IMAGEN_MODAL_GUARDADO_PARCIAL,
    IMAGEN_ERROR_404 : process.env.REACT_APP_IMAGEN_ERROR_404,
    IMAGEN_ERROR_500 : process.env.REACT_APP_IMAGEN_ERROR_500,
    ALERT_ICON : process.env.REACT_APP_ALERT_ICON,
    IMAGEN_CONFIRMACION_DE_ENVIO : process.env.REACT_APP_IMAGEN_CONFIRMACION_DE_ENVIO
};

