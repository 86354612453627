import { useNavigate } from "react-router-dom";
import LayoutDocumentos from "./layouts/layout-documentos";
import { useCallback, useEffect, useState } from "react";
import CardUploadFile from "app/components-finmaq/cardUploadFile";
import ResponsiveTooltip from "app/components-finmaq/responsiveTooltip";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import useDocumentState from "app/carga-documentos/utilities/useDocumentState";
import useGetDocuments from "app/carga-documentos/utilities/useGetDocuments"
import ModalGuardadoParcial from "app/components-finmaq/modal-guardado-parcial";
import { useParams } from 'react-router-dom';

export default function ExtractosBancarios() {
    const { productIndex } = useParams();
    const { documents } = useGetDocuments();

    const { fileStatus:fileStatusOne, 
        documentUrl:documentOneUrl, 
        setDocumentUrl:setDocumentOneUrl, 
        handleInput: handleInputOne, 
        handleSubmit: handleSubmitOne } = useDocumentState({productIndex: Number(productIndex)});

    const { fileStatus:fileStatusTwo, 
        documentUrl:documentTwoUrl, 
        setDocumentUrl:setDocumentTwoUrl, 
        handleInput: handleInputTwo, 
        handleSubmit: handleSubmitTwo } = useDocumentState({productIndex: Number(productIndex)});

    const { fileStatus:fileStatusThree, 
        documentUrl:documentThreeUrl, 
        setDocumentUrl:setDocumentThreeUrl, 
        handleInput: handleInputThree, 
        handleSubmit: handleSubmitThree } = useDocumentState({productIndex: Number(productIndex)});

    const [buttonStatus, setButtonStatus] = useState(uploadStatus.DISABLE);
    const [deletedFiles,] = useState({ extract_1: false, extract_2: false, extract_3: false });
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (buttonStatus === uploadStatus.READY) {
            if (fileStatusOne === uploadStatus.READY) handleSubmitOne("EXTRACTO_1");
            if (fileStatusTwo === uploadStatus.READY) handleSubmitTwo("EXTRACTO_2");
            if (fileStatusThree === uploadStatus.READY) handleSubmitThree("EXTRACTO_3");
            navigate(`/carga-documentos/${productIndex}`);
        }
    };

    const isCardOneFileHolder = fileStatusOne === uploadStatus.READY || fileStatusOne === uploadStatus.UPLOADING || fileStatusOne === uploadStatus.DONE || documentOneUrl;
    const isCardTwoFileHolder = fileStatusTwo === uploadStatus.READY || fileStatusTwo === uploadStatus.UPLOADING || fileStatusTwo === uploadStatus.DONE || documentTwoUrl;
    const isCardThreeFileHolder = fileStatusThree === uploadStatus.READY || fileStatusThree === uploadStatus.UPLOADING || fileStatusThree === uploadStatus.DONE || documentThreeUrl;

    const isCardTwoActive = isCardOneFileHolder || isCardTwoFileHolder;
    const isCardThreeActive = (isCardOneFileHolder && isCardTwoFileHolder) || isCardThreeFileHolder;

    const cardWidth = () => {
        if(isCardOneFileHolder && isCardTwoFileHolder){
            return "lg:w-[30%] mx-[1%]";
        }else if(isCardOneFileHolder || isCardTwoFileHolder){
            return "lg:w-[45%] mx-[1%]";
        }
        return "lg:w-[calc(93vw-160px)]";
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const isAnyFieldFilled = useCallback((): boolean => {
        return fileStatusOne === uploadStatus.READY || 
            fileStatusTwo === uploadStatus.READY || 
            fileStatusThree === uploadStatus.READY;
    }, [fileStatusOne, fileStatusTwo, fileStatusThree]);

    const handleOpenModal = useCallback((): void => {
        if (isAnyFieldFilled() || deletedFiles.extract_1 || deletedFiles.extract_2 || deletedFiles.extract_3) {
            setIsModalOpen(true);
        } else {
            navigate(`/carga-documentos/${productIndex}`);
        }
    }, [isAnyFieldFilled, deletedFiles, navigate, productIndex]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const classNameByEvent = (status : any) => {
        switch (status) {
            case uploadStatus.READY:
                return "bg-[#390094] text-white";
            case uploadStatus.DISABLE:
            default:
                return "bg-[#CBD5E0] text-[#718096] cursor-not-allowed";
        }
    };


    useEffect(()=>{
        const documentsByProductIndex = documents ? documents[productIndex as keyof typeof documents] : {};
        
        if(isCardOneFileHolder || isCardTwoFileHolder || isCardThreeFileHolder){
            setButtonStatus(uploadStatus.READY);
        } else {
            setButtonStatus(uploadStatus.DISABLE);
        }

        if(documentsByProductIndex && documentsByProductIndex.extract_1 && documentsByProductIndex.extract_1.file_path) setDocumentOneUrl(documentsByProductIndex.extract_1.file_path); 
        if(documentsByProductIndex && documentsByProductIndex.extract_2 && documentsByProductIndex.extract_2.file_path) setDocumentTwoUrl(documentsByProductIndex.extract_2.file_path); 
        if(documentsByProductIndex && documentsByProductIndex.extract_3 && documentsByProductIndex.extract_3.file_path) setDocumentThreeUrl(documentsByProductIndex.extract_3.file_path);

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = ''; 
            handleOpenModal();
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);

        
    },[documents, productIndex, setDocumentOneUrl, setDocumentTwoUrl, setDocumentThreeUrl, handleOpenModal, isCardOneFileHolder, isCardTwoFileHolder, isCardThreeFileHolder])

    return (
        <LayoutDocumentos 
            handleButtonClick={handleButtonClick} 
            buttonLabel="Guardar"
            buttonClassName={classNameByEvent(buttonStatus)}
            hasFooter={true}>
            <div className="hidden lg:flex text-[#718096] ml-[105px] text-md font-normal"><span>Solicitud de crédito </span><span className="ml-1 text-[#000000]"> / Extractos bancarios</span></div>
            <div className="lg:mt-7 lg:ml-14 flex items-center">
                {isModalOpen && (
                    <ModalGuardadoParcial onClose={handleCloseModal} onHandleProp={handleButtonClick} /> 
                )}
                <button onClick={handleOpenModal}>
                    <svg className="text-[#390094]" width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <p className="text-start text-[#390094] font-bold text-xl ml-2 lg:font-semibold lg:text-2xl lg:ml-[15px]">Extractos bancarios</p>
            </div>
            <div className="text-[#171923] text-md font-normal lg:ml-[105px] lg:mr-[105px] lg:mt-2 mt-7 leading-6 lg:leading-normal w-full lg:w-auto">
                <p className="mb-4 lg:mb-0">Suba los <strong>últimos tres extractos bancarios</strong> de su cuenta principal. Esto nos ayudará a evaluar su historial financiero.</p>
                <p>Verifique que toda la información relevante, como el nombre del titular de la cuenta y el saldo actual, sea claramente visible.</p>
            </div>
            <div className="lg:pl-[105px] lg:pr-[105px] mt-10 mt-[60px] lg:mt-[75px]">
                <strong>Últimos 3 extractos bancarios</strong>
                <ResponsiveTooltip text="Puede subir un archivo con los últimos tres extractos bancarios o cada uno por separado" color="black"/>
            </div>
            <div className="flex lg:w-[97vw] flex-col-reverse justify-center lg:flex-row lg:pl-[67px] lg:pr-[67px]">
                <CardUploadFile
                    key={`documentOne_${documentOneUrl}`}
                    fileLimit={4928307}
                    fileStatus={fileStatusOne}
                    fileName={documentOneUrl}
                    className={cardWidth()}
                    buttonLabel="Elija los archivos"
                    handleInput={handleInputOne}/>
                {(isCardTwoActive || documentTwoUrl) && (
                    <CardUploadFile
                        key={`documentTwo_${documentTwoUrl}`}
                        fileLimit={4928307}
                        fileStatus={fileStatusTwo}
                        fileName={documentTwoUrl}
                        className={cardWidth()}
                        buttonLabel="Elija los archivos"
                        handleInput={handleInputTwo}/>
                )}
                {(isCardThreeActive || documentThreeUrl) && (
                    <CardUploadFile
                        key={`documentThree_${documentThreeUrl}`}
                        fileLimit={4928307}
                        fileStatus={fileStatusThree}
                        fileName={documentThreeUrl}
                        className={cardWidth()}
                        buttonLabel="Elija los archivos"
                        handleInput={handleInputThree}/>
                )}
            </div>
        </LayoutDocumentos>
    );
}
