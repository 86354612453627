import { ConfigVariables } from "adapters/ports/config-variables";

export default function RecuperacionPassword() {

    return (
        <div className="bg-white w-full min-h-screen flex flex-col justify-between">
            <div className="absolute right-0 lg:right-16 lg:mt-10 mt-10 w-full lg:w-auto flex lg:block justify-center">
                <img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="Logo finmaq" width={170} height={47} className="lg:w-[170px] lg:h-[47px] w-[128px] h-[36px]"
                />
            </div>
            <div className="mt-40 lg:mt-32 flex items-center justify-center w-full">
                <div className="w-[340px] lg:w-[430px]">
                    <p className="text-[#390094] text-center lg:text-4xl text-2xl font-semibold">
                        ¿Olvidó su contraseña?
                    </p>
                    <div className="flex justify-center">
                        <img src={ConfigVariables.IMAGEN_CAMBIAR_PASSWORD} alt="img recuperacion contraseña" width={246} height={196} className="lg:mt-11 lg:mb-11 mt-16 mb-11"/>
                    </div>
                    <p className="text-md text-[#4A5568] text-center">
                        No se preocupe, le ayudaremos a restablecerla. <br className="lg:hidden" />
                        Por favor, envíe un correo a nuestro equipo de soporte para recibir nuevas credenciales.
                    </p>

                    <a href="mailto:ayuda@finmaq.com.co" aria-label="email de ayuda" className="text-[#3696B2] block text-center mt-10 lg:mt-0 lg:mb-0 mb-20">ayuda@finmaq.com.co</a>
                    <a href="/" aria-label="Volver al inicio" className="w-full mb-16 lg:mt-11 h-[40px] bg-[#390094] leading-2 text-white text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg">Volver al inicio</a>
                </div>
            </div>
            <div className="hidden lg:block lg:mt-11 lg:mb-6 text-sm text-[#A0AEC0] text-center lg:w-auto w-[340px] mx-auto mb-5">
                <p>© 2024 Finmaq. Todos los derechos reservados.</p>
            </div>
        </div>
    );
}
