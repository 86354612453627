import Pricing from "layouts/auth/types/Pricing";

const PricingPage = () => {
  return (
    <div>
      <Pricing />
    </div>
  );
};

export default PricingPage;
